
import PopUpCard from "../../Cards/PopUpCard/PopUpCard";
import CustomInput from '../../Customs/CustomInput/CustomInput'
import CustomButton from "../../Customs/CustomButton";
import CheckAnimation from "../../CheckAnimation/CheckAnimation";

import { useState } from "react";
import { useSelector } from "react-redux";
import { TimeOutHandler } from "../../../utils/helper_functions";

const UpdatePortalStock = (props) => {

    const api_token = useSelector(state => state.user.token)
    const API_URL = process.env.REACT_APP_API_URL
    const data = props.data

    const [stocks, setStocks] = useState(data.portals_data[0].stocksAmount)
    const [showCheckAnimation, setShowCheckAnimation] = useState(false)

    const submitHandler = async () => {

        let response = await fetch(`${API_URL}/api/v2/users/websites/portals`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                id: data.id,
                portal: data.portal,
                status: data.portals_data[0].status,
                stocksAmount: stocks
            })
        })

        const res_status = response.status
        response = await response.json()

        console.log(res_status)

        if(res_status === 200) {
            setShowCheckAnimation(true)
            TimeOutHandler(() => {
                props.reloadData()
                props.toggle()
            })
        }
    }

    return (
        <PopUpCard header='שינוי כמות מניות' toggle={props.toggle}>
           {showCheckAnimation && <CheckAnimation />}
            <div style={{display: 'flex', flexDirection: 'column', gap: '3rem', alignItems: 'center', marginTop: '2rem'}}>
                <CustomInput value={stocks} type='number' onChange={(e) => setStocks(e.target.value)}/>
                <div style={{width: 158}} onClick={submitHandler}><CustomButton name='אשר'/></div>
            </div>
        </PopUpCard>
    )

}

export default UpdatePortalStock;