import classes from './NavBarMobile.module.scss';
import NavBar from "../NavBar/NavBar";

const NavBarMobile = () => {

    return (
        <div className={classes.container}>
            <NavBar />
        </div>
    )
}

export default NavBarMobile