import classes from './PaymentUserTableItem.module.scss'

// import approved from '../../../assets/icons/approved.svg'
// import disapproved from '../../../assets/icons/disapproved.svg'
import information from '../../../assets/icons/information.svg'

import { fullYearParser, paymentsStatusesHandler } from '../../../utils/helper_functions'

import { useSelector } from 'react-redux'

const PaymentUserTableItem = (props) => {

    const data = props.data
    const added = fullYearParser(data.created)
    const approved_date = data.approved_date === 'None' ? 'טרם אושר' : fullYearParser(data.approved_date)
    const language = useSelector(state => state.ui.language.code)
    let status_class;

    const statusClassHandler = () => {
        if(data.request_status === 'processing') return classes.processing
        if(data.request_status === 'request accepted') return classes.request_accepted
        if(data.request_status === 'request declined') return classes.request_denied
        if(data.request_status === 'payment process') return classes.payment_process
        if(data.request_status === 'paid') return classes.paid
        else return classes.request_accepted
    }

    status_class = statusClassHandler()

    const showRecepitHandler = () => {
        props.showInvoice(data.receipt_img[0],data)
    }

    const showTaxInvoiceHandler = () => {
        props.showInvoice(data.invoice_img[0],data)
    }
    

    return (
        <tr className={classes.container} style={{textAlign: 'center'}}>
            <td className={[status_class,classes.status].join(' ')}>
                <div>{paymentsStatusesHandler(data.request_status,language)}</div>
                {data.request_status === 'request declined' && <img style={{left: '3rem'}} src={information} alt='סיבה' onClick={props.onShowRefuse}/>}
            </td>
            <td>{added}</td>
            <td>₪ {data.amount.NIS}</td>
            <td style={{ color:'green', gap: '1rem'}}>
                <div style={{display: 'flex', justifyContent: 'center', gap: '1rem'}}>
                {(data.request_status === 'request declined' && data.refuse_reason.receipt_status_reason) && <div className={classes.upload} onClick={()=> props.onReceiptReupload(data.paymentRequestID)}>{props.names.upload}</div>}
                <div className={classes.view} onClick={showRecepitHandler}>{props.names.view}</div>
                </div>
            </td>
            {/* <td>
                {data.receipt_status && <div className={classes.center}><img src={approved} alt='approved'/></div>}
                {!data.receipt_status && <div className={classes.center}><img src={disapproved} alt='disapproved'/></div>}
            </td>
            <td>
                {data.receipt_tax_invoice && <div className={classes.center}><img src={approved} alt='approved'/></div>}
                {!data.receipt_tax_invoice && <div className={classes.center}><img src={disapproved} alt='disapproved'/></div>}
            </td> */}
            <td style={{ gap: '1rem'}}>
                <div style={{display: 'flex', justifyContent: 'center', gap: '1rem'}}>
                {(!data.invoice_img[0] || ((data.request_status === 'request declined' && data.refuse_reason.receipt_tax_invoice_reason))) && <div className={classes.upload} onClick={() => props.onUpload(data.paymentRequestID)}>{props.names.upload}</div>}
                {data.invoice_img[0] && <div className={classes.view} onClick={showTaxInvoiceHandler}>{props.names.view}</div>}
                </div>
            </td>
            <td>{data.request_status !== 'request declined' ? approved_date : 'טרם אושר'}</td>
        </tr>
    )
}

export default PaymentUserTableItem
