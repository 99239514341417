import classes from './Customers.module.scss'

import CustomButton from '../Customs/CustomButton';
import SearchBar from '../SearchBar/SearchBar';
import AmountSelect from '../AmountSelect/AmountSelect';
import Pagination from '../Pagination/Pagination';
import ResponsiveTable from '../Customs/ResponsiveTable/ResponsiveTable';
import CustomSelect from '../Customs/CustomSelect/CustomSelect';

import CustomersHeaders from './CustomersHeaders';
import CustomersTableItem from './CustomersTableItem/CustomersTableItem';
import EditUserWebsites from '../EditUserWebsites/EditUserWebsites';
import AddNewUser from '../AddNewUser/AddNewUser';
import CreateTransferPayment from '../CreateTransferPayment/CreateTransferPayment';
import ViewCustomerTranscations from '../ViewCustomerTransactions/ViewCustomerTransactions';

import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { uiActions } from '../../store/ui-slice';
import { clearAllTimeouts, TimeOutHandler } from '../../utils/helper_functions';


const Customers = () => {
    
    const names = useSelector(state => state.ui.language.values.adminTable)
    const api_token = useSelector(state => state.user.token)
    const navigateToCustomers = useSelector(state => state.ui.navigateToCustomers)
    const isAdmin = useSelector(state => state.user.isAdmin)

    const websiteSearchParam = window.location.search.split('=')[1]

    const dispatch = useDispatch()

    const [loadingResults, setLoadingResults] = useState(true)
    const [showEditUserWebistes, setShowEditUserWebistes] = useState(false)
    const [showAddNewUser, setShowAddNewUser] = useState(false)
    const [showEditUser, setShowEditUser] = useState(false)
    const [showCreateTransferPayment, setShowCreateTransferPayment] = useState(false)
    const [showViewCustomerTranscations, setShowViewCustomerTranscations] = useState(false)

    const [searchBy, setSearchBy] = useState(websiteSearchParam?.includes('@') ? 'email' :'name')

    const [searchResults, setSearchResults] = useState(websiteSearchParam ? decodeURI(websiteSearchParam) : '')
    const [pageNumber, setPageNumber] = useState(1)
    const [numberOfResults, setNumberOfResults] = useState(20)
    const [numberOfPages, setNumberOfPages] = useState(5)

    const [Data, setData] = useState([])

    const [importStatus, setimportStatus] = useState('ACTIVE')

    const [editUserWebsitesData, setEditUserWebsitesData] = useState([])
    const [editUserData, setEditUserData] = useState({})
    const [createTransferData, setCreateTrasferData] = useState({websites: []})
    const [showCustomerTransfersData, setShowCustomerTransferData] = useState({})

    const [forceReload, setForceReload] = useState(false)

    const API_URL = process.env.REACT_APP_API_URL

    useEffect(async () => {

        setLoadingResults(true)

        // This navigation occurs only after Impersonating to customer
        if(navigateToCustomers) dispatch(uiActions.setNavigateToCustomers(false))

        if(searchResults.length === 0) {
            await importCustomersData()
            setLoadingResults(false)
        }
        else {
            clearAllTimeouts()

            TimeOutHandler(async() => {
        
                await importCustomersData()
                setLoadingResults(false)
        
            },600)
        }
    },[importStatus,pageNumber,numberOfResults,searchResults, forceReload])


    const importCustomersData = async () => {

        const response = await fetch(`${API_URL}/api/v2/employees/customers/list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                items: numberOfResults, 
                page: pageNumber,
                status: importStatus, 
                searchName: searchBy === 'name' ? searchResults: null,
                search: searchBy !== 'name' ? searchResults : null})
        })

        const res_status = response.status
        const data = await response.json()

        if(res_status === 200 && data.result !== null) {
            setNumberOfPages(Math.ceil(data.entries/numberOfResults))
            setData(data.result)
        }
    }

    const forceReloadHandler = () => {setForceReload(val => !val)}

    const toggleEditUserWebsites = () => {setShowEditUserWebistes(val => !val)}

    const toggleShowAddNewUser = () => {setShowAddNewUser(val => !val)}

    const toggleEditUser = () => {setShowEditUser(val => !val)}

    const toggleCreatePayment = () => {setShowCreateTransferPayment(val => !val)}

    const toggleViewCustomerTransactions = () => {setShowViewCustomerTranscations(val => !val)}

    const NumberOfResultsHandler = (e) => {
        setPageNumber(1)
        setNumberOfResults(e.target.value)
    }

    const EditUserWebistesHandler = (data) => {
        setEditUserWebsitesData(data)
        setShowEditUserWebistes(true)
    }

    const EditUserHandler = (data) => {
        if(!isAdmin) {
            alert('אין לך השראות לביצוע פעולה זו')
            return
        }
        setEditUserData(data)
        setShowEditUser(true)
    }

    const newTransferHandler = (data) => {
        setCreateTrasferData(data)
        setShowCreateTransferPayment(true)
    }

    const viewCustomerTranscationsHandler = (data) => {
        setShowCustomerTransferData(data)
        setShowViewCustomerTranscations(true)
    }

    const importStatusHandler = (e) => {
        const value = e.target.value

        if(value === 'All') setimportStatus(undefined)
        else setimportStatus(e.target.value)
    }

    return (
        <>
        {(showEditUserWebistes && isAdmin) && <EditUserWebsites toggle={toggleEditUserWebsites} edit={false} data={editUserWebsitesData} updateData={setEditUserWebsitesData} forceReload={forceReloadHandler}/>}
        {(showAddNewUser && isAdmin) && <AddNewUser toggle={toggleShowAddNewUser} />}
        {(showEditUser && isAdmin) && <AddNewUser toggle={toggleEditUser} edit={true} data={editUserData}/>}
        {(showCreateTransferPayment && isAdmin) && <CreateTransferPayment toggle={toggleCreatePayment} data={createTransferData}/>}
        {showViewCustomerTranscations && <ViewCustomerTranscations toggle={toggleViewCustomerTransactions} data={showCustomerTransfersData}/>}
        <div className={classes.container}>
            <div className={classes.main}>

                <div className={classes.main_top}>
                    <div className={classes.main_title}>{names.title}</div>
                    <div className={classes.main_buttons}>
                        {isAdmin && <div onClick={toggleShowAddNewUser}><CustomButton name={names.add_new_user} color='red' reverse/></div>}
                    </div>
                </div>

                <div className={classes.main_middle}>
                    <div className={classes.middle_left}>
                        <SearchBar placeholder={searchBy === 'name' ? 'Search by Name' : 'Search By Email'} value={searchResults} onChange={(e) => setSearchResults(e.target.value)}/>
                        <CustomSelect value={searchBy} onChange={(e) => setSearchBy(e.target.value)}>
                            <option value='name'>חיפוש על פי שם</option>
                            <option value='email'>חיפוש ע"פ דוא"ל</option> 
                        </CustomSelect>
                        <CustomSelect value={importStatus} onChange={importStatusHandler}>
                            <option value={'ACTIVE'}>Active</option>
                            <option value={'INACTIVE'}>Inactive</option>
                            <option value={'All'}>All</option>
                        </CustomSelect>
                    </div>
                    <AmountSelect pageNumber={pageNumber} numberOfResults={numberOfResults} numberOfPages={numberOfPages} onChange={NumberOfResultsHandler}/>
                </div>

                <ResponsiveTable loadingResults={loadingResults}>
                    <thead>
                        <CustomersHeaders names={names}/>
                    </thead>
                    <tbody className={classes.table_data}>
                        {Data.map((item,index) => 
                        <CustomersTableItem key={index} data={item} 
                        onWebsiteClick={() => EditUserWebistesHandler(item)} 
                        onEditClick={() => EditUserHandler(item)}
                        onCreateTransfer={() => newTransferHandler(item)}
                        onViewCustomerTranscations={() => viewCustomerTranscationsHandler(item)}/>)}
                    </tbody>
                </ResponsiveTable>

                <div className={classes.main_bottom}>
                    <AmountSelect pageNumber={pageNumber} numberOfResults={numberOfResults} numberOfPages={numberOfPages} onChange={NumberOfResultsHandler}/>
                    {numberOfPages > 1 && <Pagination pageNumber={pageNumber} setPageNumber={setPageNumber} numberOfPages={numberOfPages}/>}
                </div>

            </div>
        </div>
        </>
    )

}

export default Customers;