import { useSelector } from "react-redux";

const TransactionsHeaderUser = (props) => {

    const names = props.names
    // const isAdmin = useSelector(state => state.user.isAdmin)
    // const showAdminInterface = useSelector(state => state.ui.showAdminInterface)

    return (
        <tr>
            <th>{names.domain}</th>
            <th>{names.type}</th>
            <th>{names.USD}</th>
            <th>{names.NIS}</th>
            <th>{names.rate}</th>
            <th>{names.added}</th>
            <th>{names.count}</th>
            <th>{names.paid}</th>
            {/* {(isAdmin && showAdminInterface) && <th>{names.actions}</th>} */}
        </tr>
    )
}

export default TransactionsHeaderUser;