import classes from './GrayWindow.module.scss'

/**
 * toggle - function responsible on closing the component.
 * layerTwo - boolean that increase the z-index of the component (if true)
 * 
 * @param {Function} toggle
 * @param {boolean} layerTwo 
 * @returns 
 */
const GrayWindow = (props) => {

    return (
        <div className={classes.container} style={props.layerTwo ? {zIndex: '15000'} : {}}>
            <div className={classes.background} onClick={props.toggle}></div>
            <div className={classes.main} >
                {props.children}
            </div>
        </div>
    )
}

export default GrayWindow