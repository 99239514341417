import classes from './CreateTransferPayment.module.scss'

import exit from '../../assets/icons/exit.svg'

import TransferStack from './TransferStack/TransferStack'

import GrayWindow from '../Cards/GrayWindow/GrayWindow';
import CustomSelect from '../Customs/CustomSelect/CustomSelect';
import CustomInput from '../Customs/CustomInput/CustomInput';
import CustomButton from '../Customs/CustomButton';
import CheckAnimation from '../CheckAnimation/CheckAnimation';
import FailAnimation from '../FailAnimation/FailAnimation';

import { fullYearParser, TimeOutHandler } from '../../utils/helper_functions';

import { useState } from 'react';
import { useSelector } from 'react-redux';

const CreateTransferPayment = (props) => {

    const data = props.data
    const names = useSelector(state => state.ui.language.values.newTransfer)
    const api_token = useSelector(state => state.user.token)

    const [showCustomType, setShowCustomType] = useState(false)
    const [allowCount, setAllowCount] = useState(false)

    const [selectorValue, setSelectorValue] = useState('Data Monetization / Sponsered Content')
    const [customType, setCustomType] = useState('')
    const [website, setWebsite] = useState(data.websites[0].domain)
    const [count, setCount] = useState(1)
    const [amount, setAmount] = useState('')
    const [rate, SetRate] = useState('')
    const [date, setDate] = useState('')
    const [transferStack, setTransferStack] = useState([])

    const [showCheckAnimation, setShowCheckAnimation] = useState(false)
    const [showFailAnimation, setShowFailAnimation] = useState(false)
    const [showStack, setShowStack] = useState(false)

    const API_URL = process.env.REACT_APP_API_URL

    const selectorHandler = (e) => {
        const value = e.target.value

        if(value === 'custom') setShowCustomType(true)

        if(value.includes('Ad Views')) setAllowCount(true)
        else {
            if(allowCount) setAllowCount(false); setCount(1);
        }

        setSelectorValue(value)
    }

    const submitHandler = async () => {

        if(transferStack.length > 0) {

            let response = await fetch(`${API_URL}/api/v2/employees/transfers`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${api_token}`},
                body: JSON.stringify({
                    customerID: data.customerID,
                    transfers: transferStack
                })
            })

            let res_status = response.status
            response = await response.json()

            if(res_status === 200) {
                setShowCheckAnimation(true)
                TimeOutHandler(() => props.toggle())
            }
            else {
                setShowFailAnimation(true)
                TimeOutHandler(() => setShowFailAnimation(false))
            }

        }
    }

    const addToStackHandler = () => {

        const selected_website = data.websites.find(web => web.domain === website)
        let today = new Date()
        today = fullYearParser(today).repeat('/','-')

        let temp_obj = {
            added: date,
            amount: amount,
            count: count,
            nisRate: rate,
            type: showCustomType ? customType : selectorValue,
            websiteID: selected_website.websiteID,
            domain: selected_website.domain
        }

        setTransferStack(prev => [...prev,temp_obj])

        setSelectorValue('Data Monetization / Sponsered Content')
        setShowCustomType(false)
        setWebsite(data.websites[0].domain)
        setCount(1)
        setAmount('')
        SetRate('')
        setDate('')

    }

    const exitCustomInputMode = () => {
        setSelectorValue('Data Monetization / Sponsered Content')
        setShowCustomType(false)
    }
    
    const countHandler = (e) => {
        const value = e.target.value
        if(!isNaN(value*1)) {
            setCount(e.target.value*1)
        }
    }

    const amountHandler = (e) => {
        const value = e.target.value
        if(!isNaN(value*1)) {
            setAmount(e.target.value)
        }
    }

    const rateHandler = (e) => {
        const value = e.target.value
        if(!isNaN(value*1)) {
            SetRate(e.target.value)
        }
    }

    const toggleShowStack = () => { setShowStack(val => !val) }
        

    return (
        <GrayWindow toggle={props.toggle}>
            <div style={{position: 'relative', overflow: 'hidden'}}>
            <div className={classes.main_top}>
                <span>{`${names.create} - ${data.firstName} ${data.lastName}`}</span>
                <div style={{width: 140}} onClick={toggleShowStack}><CustomButton name={showStack ? names.hide_stack : names.show_stack}/></div>
                <img src={exit} alt='X' onClick={props.toggle}/>
            </div>
            {showCheckAnimation && <CheckAnimation />}
            {showFailAnimation && <FailAnimation />}
            {showStack && <TransferStack data={transferStack}/>}
            <form className={classes.main_middle}>

                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{names.type}</label>
                    <div className={classes.middle_item_input}>
                        {!showCustomType &&
                        <div className={classes.selector}>
                        <CustomSelect value={selectorValue} onChange={selectorHandler} required>
                            <option>Data Monetization / Sponsered Content</option>
                            <option>Ad Views (Programmatic / oRTB)</option>
                            <option>XML / Email / Native / Push / Cross-Promotion</option>
                            <option>Affiliets / Products / ForeMedia / Direct Ads</option>
                            <option value='custom'>Custom Type</option>
                        </CustomSelect>
                        </div>                     
                        }
                        {showCustomType &&
                        <div className={classes.custominput}>
                            <img className={classes.custominput_exit} src={exit} onClick={exitCustomInputMode}/>
                            <CustomInput placeholder='Custom Type' value={customType} required onChange={(e) => setCustomType(e.target.value)}/>
                        </div>
                        }
                    </div>
                </div>

                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{names.website}</label>
                    <div className={[classes.middle_item_input,classes.selector].join(' ')}>
                        <CustomSelect value={website} onChange={(e) => setWebsite(e.target.value)} required>
                            {data.websites.map((item,index) => <option key={index} value={item.domain}>{item.domain}</option>)}
                        </CustomSelect>                        
                    </div>
                </div>

                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{names.count}</label>
                    <div className={classes.middle_item_input}>
                        <CustomInput placeholder='Count' value={count} required onChange={countHandler} disabled={!allowCount}/>
                    </div>
                </div>

                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{names.amount_usd}</label>
                    <div className={classes.middle_item_input}>
                        <CustomInput placeholder='Amount USD' value={amount} required onChange={amountHandler}/>
                    </div>
                </div>

                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{names.nis_rate}</label>
                    <div className={classes.middle_item_input}>
                        <CustomInput placeholder='NIS Rate' value={rate} required onChange={rateHandler}/>
                    </div>
                </div>

                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{names.date}</label>
                    <div className={classes.middle_item_input}>
                        <CustomInput placeholder='Custom Type' value={date} type='date' onChange={(e) => setDate(e.target.value)} required/>
                    </div>
                </div>

                <div className={classes.buttons}>
                        <div className={classes.customButton} onClick={props.toggle}><CustomButton color='red' name={names.cancel}/></div>
                        <div className={classes.customButton} onClick={addToStackHandler}><CustomButton reverse color='blue' name={names.add} /></div>
                        <div className={classes.customButton} onClick={submitHandler}><CustomButton reverse color='red' name={names.submit}/></div>
                </div>

            </form>
            </div>
        </GrayWindow>
    )
}

export default CreateTransferPayment;