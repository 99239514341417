import classes from './WebsiteStatusHistory.module.scss'

import PopUpCard from '../Cards/PopUpCard/PopUpCard'
import ResponsiveTable from '../Customs/ResponsiveTable/ResponsiveTable'

import { fullYearParser, statusParser } from '../../utils/helper_functions'

const WebsiteStatusHistory = (props) => {

    return (
        <PopUpCard toggle={props.toggle} header={'Website Status History'}>
            <div className={classes.main_middle}>
                <ResponsiveTable>
                    <thead>
                        <tr>
                            <th>Date Added</th>
                            <th>Status</th>
                            <th>Customer</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.map((item,index) => {
                            return (
                                <tr key={index} className={classes.center}>
                                    <td>{fullYearParser(item.added*1000)}</td>
                                    <td>{statusParser(item.status)}</td>
                                    <td>{`${item.customer.firstName} ${item.customer.lastName}`}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </ResponsiveTable>
            </div>
        </PopUpCard>
    )

}

export default WebsiteStatusHistory