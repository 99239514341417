import classes from './ResetPassword.module.scss'

// import logo from '../../assets/images/foremedia.svg';
import foreshop from '../../assets/images/foreshop_logo.png'
import lock from '../../assets/icons/lock.svg'
import lock_red from '../../assets/icons/lock_red.svg'
import lock_blue from '../../assets/icons/lock_blue.svg'
import lock_green from '../../assets/icons/lock_green.svg'

import CustomInput from '../Customs/CustomInput/CustomInput';
import CustomButton from '../Customs/CustomButton';
import CheckAnimation from '../CheckAnimation/CheckAnimation';

import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { generateChat } from '../../utils/helper_functions'

const ResetPassword = () => {

    const urlHash = window.location.hash.replace('#','')
    const navigate = useNavigate()

    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [newPasswordIcon, setNewPasswordIcon] = useState(lock)
    const [confirmPasswordIcon, setConfirmPasswordIcon] = useState(lock)

    const [newPasswordStatus, setNewPasswordStatus] = useState('pending')
    const [confirmPasswordStatus, setConfirmPasswordStatus] = useState('pending')

    const [showCheckAnimation, setShowCheckAnimation] = useState(false)

    const api_token = useRef(null)

    const API_URL = process.env.REACT_APP_API_URL

    // generateChat('OBSERVER');

    useEffect(() => {

        fetch(`${API_URL}/api/v1/recovery/verify/${urlHash}`)
        .then(res => {
            if(res.status !== 200) {return {access_token: false}}
            else {return res.json()}  
        })
        .then(res => {
            if(res.access_token) {
                api_token.current = res.access_token
            }
            else {
                navigate('/login', {replace: true})
            }
        })

    },[])

    const newPasswordHandler = (e) => {
        let value = e.target.value

        if(value.length === 0) {
            setNewPasswordIcon(lock)
        }
        else if(value.length >= 4) { 
            setNewPasswordStatus('success')
            setNewPasswordIcon(lock_green)
        }
        else { 
            setNewPasswordStatus('pending') 
            setNewPasswordIcon(lock_blue)
        }

        setNewPassword(value)
    }

    const confirmPasswordHandler = (e) => {
        let value = e.target.value

        if(value.length === 0) {
            setConfirmPasswordIcon(lock)
        }
        else if(value.length >= 4 && (value.localeCompare(newPassword) === 0)) {
            setConfirmPasswordStatus('success')
            setConfirmPasswordIcon(lock_green)
        }
        else if((value.length >= newPassword.length && (value.localeCompare(newPassword) !== 0)) || !newPassword.startsWith(value)) {
            setConfirmPasswordStatus('error')
            setConfirmPasswordIcon(lock_red)
        }
        else { 
            setConfirmPasswordStatus('pending') 
            setConfirmPasswordIcon(lock_blue)
        }

        setConfirmPassword(value)
    }

    const submitHandler = async (e) => {
        e.preventDefault()

        if(newPasswordStatus === 'success' && confirmPasswordStatus === 'success') {

            const request_options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${api_token.current}`
                },
                body: JSON.stringify({
                    newPassword: newPassword
                })
            }

            let response = await fetch(`${API_URL}/api/v1/recovery/final`,request_options)
            const res_status = response.status
            response = await response.json()

            if(res_status === 200) {
                setShowCheckAnimation(true)
                setTimeout(() => {
                    navigate('/login', {replace: true})
                }, 5000);
            }
            else {
                alert('nav to recover')
            }
        }

    }

    return (
        <div className={classes.container}>
            <div className={classes.main}>

                <div className={classes.top_container}>
                    <div className={classes.logo}><img src={foreshop} alt='ForeMedia'/></div>
                    <span className={classes.title}>Reset Password</span>
                </div>

                <div className={classes.form_container}>
                    <form className={classes.form} onSubmit={submitHandler}>
                        {showCheckAnimation && <CheckAnimation height={107}/>}
                        <div className={classes.form_item}>
                            <label htmlFor='newpass'>New Password</label>
                            <CustomInput image_src={newPasswordIcon} placeholder='************' type='password' onChange={newPasswordHandler} value={newPassword} required status={newPasswordStatus}/>
                        </div>
                        <div className={classes.form_item}>
                            <label htmlFor='confirm'>Confirmation</label>
                            <CustomInput image_src={confirmPasswordIcon} placeholder='************' type='password' onChange={confirmPasswordHandler} value={confirmPassword} required status={confirmPasswordStatus}/>
                        </div>

                        <div className={classes.buttons}>
                            <div className={classes.customButton}><CustomButton reverse color='red' name='Update Password' type='submit'/></div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )

}

export default ResetPassword