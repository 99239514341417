import classes from './DragAndDrop.module.scss'

import exit from '../../assets/icons/exit.svg';
import upload from '../../assets/icons/upload.svg'

import GrayWindow from '../Cards/GrayWindow/GrayWindow';
import CheckAnimation from '../CheckAnimation/CheckAnimation'
import FailAnimation from '../FailAnimation/FailAnimation'
import LoadingTable from '../LoadingTable/LoadingTable';
import Dropzone from 'react-dropzone'
import CustomButton from '../Customs/CustomButton';

import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { TimeOutHandler } from '../../utils/helper_functions';

const DragAndDrop = (props) => {

    const legal_status = useSelector(state => state.user.paymentDetails.legal_status)
    const names = useSelector(state => state.ui.language.values.websitesContent.dragAndDrop)
    const api_token = useSelector(state => state.user.token)

    const total = props.total*1
    const vat = (props.total*0.17).toFixed(2)*1
    const total_plus_vat = (total+vat).toFixed(2)

    const [showCheckAnimation, setCheckAnimation] = useState(false)
    const [showFailAnimation, setFailAnimation] = useState(false)
    const [showLoadingAnimation, setShowLoadingAnimation] = useState(false)
    const [showSuccessMsg, setShowSuccessMsg] = useState(false)

    const [fileState, setFileState] = useState()

    const form_data = new FormData()

    const API_URL = process.env.REACT_APP_API_URL

    const submitHandler = async () => {

        const file_name = props.isTaxInvoice ? 'invoice_file' : 'receipt_file'

        setCheckAnimation(false)
        setShowLoadingAnimation(true)

        // Case Updating paymentRequest
        if(props.paymentRequestId) {

            form_data.append('paymentRequestID', props.paymentRequestId)
            form_data.append(file_name,fileState)

            if(props.isReceiptReupload) {

                let response = await fetch(`${API_URL}/api/v2/paymentrequestcontroller`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${api_token}`},
                    body: JSON.stringify({
                        paymentRequestID: props.paymentRequestId,
                        request_status: 'processing',
                        receipt_status_reason: ''
                    })
                })

                response = await response.json()

            }
        }
        // Case Creating paymentRequest
        else {

            const paymentRequestID = await props.createPaymentHandler()
            if(paymentRequestID) {
                form_data.append('paymentRequestID', paymentRequestID)
                form_data.append(file_name,fileState)
            }
            else {
                setShowLoadingAnimation(false)
                setFailAnimation(true)
                return;
            }
        }


        const status = await uploadFilesAndUpdate()

        if(status) {
            setShowSuccessMsg(true)
            setShowLoadingAnimation(false)
            setCheckAnimation(true)

            TimeOutHandler(() => {
                props.toggle()
                props.onFinish()
                props.forceReload()
            },7500)

        }
        else {
            setShowLoadingAnimation(false)
            setFailAnimation(true)
        }

    }


    const fileUploadedHandler = useCallback( async (acceptedFiles) => {

        const accepted=['image/jpeg','image/png','application/pdf']

        if(accepted.includes(acceptedFiles[0].type)) {

            setCheckAnimation(true)
            setFileState(acceptedFiles[0])

        }
        else {
            setFailAnimation(true)
        }

    })

    const tryAgainHandler = () => {
        setFailAnimation(false)
    }

    const uploadFilesAndUpdate = async () => {

        let response = await fetch(`${API_URL}/api/v2/paymentrequest`, {
            method: 'PUT',
            headers: {
                'authorization': `Bearer ${api_token}`},
            body: form_data
        })

        const res_status = response.status
        response = await response.json()

        if(res_status !== 200) {
            setShowLoadingAnimation(false)
            showFailAnimation(true)
            alert('error with uploading')
        }

        return (res_status === 200);
    }


    return (
        <GrayWindow toggle={props.toggle}>
            {(!props.isTaxInvoice && !props.isReceiptReupload) &&
            <div className={classes.totals}>
                {legal_status === 'Private' && <span style={{fontWeight: 500}}>₪ {`סה"כ: ${total} `}</span>}
                {legal_status !== 'Private' && <span>₪ {`סה"כ לא כולל מע"מ: ${total} `}</span>}
                {legal_status !== 'Private' && <span>₪{` מע"מ: ${vat}`}</span>}
                {legal_status !== 'Private' && <span style={{fontWeight: 500}}>₪ {`סה"כ כולל מע"מ: ${total_plus_vat}`}</span>}
            </div>}
            <div className={classes.main}>
                <div className={classes.main_top}>
                    <span style={{width: '80%', textAlign: 'center'}}>{`${props.isTaxInvoice ? names.invoice : names.receipt}`}</span>
                    <img src={exit} alt='X' onClick={props.toggle}/>
                </div>
                {(!showCheckAnimation && !showFailAnimation) && <div className={classes.note}>{' PDF,JPG,PNG לקוח יקר, ניתן לעלות רק קבצי*'}</div>}
                {(!showCheckAnimation && !showFailAnimation) && <div className={classes.note}>{'במידה ובחרת לעלות חשבונית ידנית, יש להוסיף על החשבונית "נאמן למקור" וחתימה ליד*'}</div>}
                {(!showCheckAnimation && !showFailAnimation && !showLoadingAnimation) &&
                <Dropzone onDrop={acceptedFiles => fileUploadedHandler(acceptedFiles)} >
                    {({getRootProps, getInputProps}) => (
                        <section className={classes.draganddrop}>
                        <div {...getRootProps()}>
                            <input {...getInputProps()}/>
                            <div className={classes.image_container}>
                                <img src={upload} alt='upload'/>
                            </div>
                        </div>
                        </section>
                    )}
                </Dropzone>}

                {showCheckAnimation && <div className={classes.checkanimation}><CheckAnimation /></div>}
                {showFailAnimation && <div className={classes.checkanimation}><FailAnimation /></div>}
                {showLoadingAnimation && <div className={classes.checkanimation}><LoadingTable /></div>}

                {showCheckAnimation &&
                <>
                <div className={classes.submit}>
                    <CustomButton name={names.submit} color='blue' onClick={submitHandler} disabled={showSuccessMsg}/>
                </div>
                {showSuccessMsg && <div className={classes.success_msg}>לקוח יקר, בקשתך הועברה בהצלחה!</div>}
                </>
                }
                {showFailAnimation &&
                <div className={classes.submit}>
                    <CustomButton name={names.retry} color='red' reverse onClick={tryAgainHandler}/>
                </div>                
                }
            </div>
        </GrayWindow>
    )
}

export default DragAndDrop;