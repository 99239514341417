import classes from './AddNewUser.module.scss'

import exit from '../../assets/icons/exit.svg'
import firstname from '../../assets/icons/firstname.svg'
import family from '../../assets/icons/family.svg'
import company from '../../assets/icons/company.svg'
import city from '../../assets/icons/city.svg'
import street from '../../assets/icons/street.svg'
import phone from '../../assets/icons/phone.svg'
import email_icon from '../../assets/icons/email.svg'
import lock from '../../assets/icons/lock.svg'

import GrayWindow from "../Cards/GrayWindow/GrayWindow"
import CustomInput from '../Customs/CustomInput/CustomInput'
import CustomButton from '../Customs/CustomButton'
import CheckAnimation from '../CheckAnimation/CheckAnimation'
import FailAnimation from '../FailAnimation/FailAnimation'
import PopUpCard from '../Cards/PopUpCard/PopUpCard'

import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
const AddNewUser = (props) => {

    const api_token = useSelector(state => state.user.token)
    const names = useSelector(state => state.ui.language.values.newCustomer)
    const API_URL = process.env.REACT_APP_API_URL

    const [firstNameEng, setFirstNameEng] = useState('')
    const [lastNameEng, setLastNameEng] = useState('')
    const [firstNameHeb, setFirstNameHeb] = useState('')
    const [lastNameHeb, setLastNameHeb] = useState('')
    const [companyEnglish, setCompanyEnglish] = useState('')
    const [companyHebrew, setCompanyHebrew] = useState('')
    const [cityEnglish, setCityEnglish] = useState('')
    const [cityHebrew, setCityHebrew] = useState('')
    const [streetEnglish, setStreetEnglish] = useState('')
    const [streetHebrew, setStreetHebrew] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [showCheckAnimation, setShowCheckAnimation] = useState(false)
    const [showFailAnimation, setShowFailAnimation] = useState(false)

    useEffect(() => {
        if(props.edit) {
            const data = props.data

            console.log(data)

            setFirstNameEng(data.firstNameENG)
            setLastNameEng(data.lastNameENG)
            setFirstNameHeb(data.firstName)
            setLastNameHeb(data.lastName)
            setCompanyEnglish(data.companyNameENG)
            setCompanyHebrew(data.companyName)
            setCityEnglish(data.cityENG)
            setCityHebrew(data.city)
            setStreetEnglish(data.streetENG)
            setStreetHebrew(data.street)
            setPhoneNumber(data.phone)
            setEmail(data.email)

        }
    },[])

    const submitHandler = async (e) => {
        e.preventDefault()

        if(password.localeCompare(confirmPassword) === 0) {
            let response = await fetch(`${API_URL}/api/v2/employees/customers`, {
                method: props.edit ? 'PUT' : 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${api_token}`},
                body: JSON.stringify({
                    customerID: props.edit ? props.data.customerID : undefined,
                    cityENG: cityEnglish,
                    cityHEB: cityHebrew,
                    companyNameENG: companyEnglish,
                    companyNameHEB: companyHebrew,
                    email: !props.edit ? email : email === props.data?.email ? undefined : email,
                    firstNamENG: firstNameEng,
                    firstNameHEB: firstNameHeb,
                    lastNameENG: lastNameEng,
                    lastNameHEB: lastNameHeb,
                    password: password,
                    phone: phoneNumber,
                    streetENG: streetEnglish,
                    streetHEB: streetHebrew
                })
            })
            const res_status = response.status
            response = await response.json()

            if(res_status===200) setShowCheckAnimation(true)
            else { failHandler() }
            
        }
    }

    const failHandler = () => {
        setShowFailAnimation(true)

        setTimeout(() => {
            setShowFailAnimation(false)
        }, 3000);
    }



    return (
        <PopUpCard toggle={props.toggle} header={props.edit ? names.editUser : names.addNew}>
                {showCheckAnimation && <CheckAnimation />}
                {showFailAnimation && <FailAnimation />}
                <form className={classes.form} onSubmit={submitHandler}>
                    <div>
                        <label>{names.firstNameENG}</label>
                        <CustomInput value={firstNameEng} onChange={(e) => setFirstNameEng(e.target.value)} image_src={firstname}/>
                    </div>
                    <div>
                        <label>{names.firstNameHEB}</label>
                        <CustomInput value={firstNameHeb} onChange={(e) => setFirstNameHeb(e.target.value)} image_src={firstname} required/>
                    </div>
                    <div>
                        <label>{names.lastNameENG}</label>
                        <CustomInput value={lastNameEng} onChange={(e) => setLastNameEng(e.target.value)} image_src={family}/>
                    </div>
                    <div>
                        <label>{names.lastNameHEB}</label>
                        <CustomInput value={lastNameHeb} onChange={(e) => setLastNameHeb(e.target.value)} image_src={family} required/>
                    </div>
                    <div>
                        <label>{names.companyNameENG}</label>
                        <CustomInput value={companyEnglish} onChange={(e) => setCompanyEnglish(e.target.value)} image_src={company}/>
                    </div>
                    <div>
                        <label>{names.companyNameHEB}</label>
                        <CustomInput value={companyHebrew} onChange={(e) => setCompanyHebrew(e.target.value)} image_src={company}/>
                    </div>
                    <div>
                        <label>{names.cityENG}</label>
                        <CustomInput value={cityEnglish} onChange={(e) => setCityEnglish(e.target.value)} image_src={city}/>
                    </div>
                    <div>
                        <label>{names.cityHEB}</label>
                        <CustomInput value={cityHebrew} onChange={(e) => setCityHebrew(e.target.value)} image_src={city}/>
                    </div>
                    <div>
                        <label>{names.streetENG}</label>
                        <CustomInput value={streetEnglish} onChange={(e) => setStreetEnglish(e.target.value)} image_src={street}/>
                    </div>
                    <div>
                        <label>{names.streetHEB}</label>
                        <CustomInput value={streetHebrew} onChange={(e) => setStreetHebrew(e.target.value)} image_src={street}/>
                    </div>
                    <div className={classes.twocolumns}>
                        <label>{names.phone}</label>
                        <CustomInput value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} image_src={phone} required/>
                    </div>
                    <div className={classes.twocolumns}>
                        <label>{names.email}</label>
                        <CustomInput value={email} onChange={(e) => setEmail(e.target.value)} image_src={email_icon} required type='email'/>
                    </div>
                    <div className={classes.twocolumns}>
                        <label>{names.password}</label>
                        <CustomInput value={password} onChange={(e) => setPassword(e.target.value)} image_src={lock} required={!props.edit} type='password'/>
                    </div>
                    <div className={classes.twocolumns}>
                        <label>{names.repeat}</label>
                        <CustomInput value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} image_src={lock} required={!props.edit} type='password'/>
                    </div>
                    <div className={classes.buttons}>
                        <div className={classes.button} onClick={props.toggle}><CustomButton name={names.cancel} color='red' reverse/></div>
                        <div className={classes.button}><CustomButton name={names.submit} color='blue' reverse type='submit'/></div>
                    </div>
                </form>
        </PopUpCard>
    )

}

export default AddNewUser