import { fullYearParser, hoursMinutesParser } from '../../../utils/helper_functions'
import classes from './LogsTableItem.module.scss'

const LogsTableItem = (props) => {

    const data = props.data

    const date = new Date(data.date)
    const fullyear = fullYearParser(date)
    const date_hour = hoursMinutesParser(date)

    const email = data.details.who.email
    const firstName = data.details.who.firstName?.HEB
    const lastName = data.details.who.lastName?.HEB
    let details = ''
    let details_class = ''

    const detailsHandler = (type,data,fulldetails) => {

        let lastName;
        let firstName;
        let email;
        
        switch(type) {
            case 'impersonate':
                details =  `Logged in under ${data.customer}`
                details_class = classes.impersonate
                break;
            case 'customer_create':
                details = `Created New Customer : | ${data.email} - ${data.lastName} ${data.firstName} |`
                details_class = classes.customer_create
                break;
            case 'website_status':
                details = `Changed ${data.domain} | from ${data.from} to ${data.to}`
                details_class = data.to === 'CUSTOMER_ACTIVE' ? classes.website_status_green : classes.website_status_red
                break;
            case 'onair':
                details = `Created On Air Campaign | For ${data.customer} With ${data.domain}`
                details_class = classes.onair
                break;
            case 'transfer':

                lastName = fulldetails.to.lastName.HEB
                firstName = fulldetails.to.firstName.HEB
                email = fulldetails.to.email

                details = `Created ${data.length} Transfers | ₪ ${data.map((data) => data.amount).reduce((prev,curr) => parseInt(prev)+parseInt(curr))} | to ${lastName} ${firstName} ${email}`
                details_class = classes.transfer
                break;
            case 'transfer_delete':

                lastName = fulldetails.to.lastName.HEB
                firstName = fulldetails.to.firstName.HEB
                email = fulldetails.to.email

                details = `Deleted ${data.count} Transfers | ${data.amount.NIS} ₪ | from ${lastName} ${firstName} ${email}`
                details_class = classes.transfer_delete
                break;
            case 'transfer_move':
                details = `Moved ${data.count} Transferss | ${data.amount} ₪ | From ${data.original_customer} To ${data.destination_customer}`
                details_class = classes.transfer_move
                break;
            case 'onair_delete':
                details = `Deleted On Air Campaign | For ${data.customer} With ${data.domain}`
                details_class = classes.onair_delete
            default:
                return;
        }

    }

    detailsHandler(data.details.type,data.details.details,data.details)

    return (
        <tr style={{cursor: data.details.type === 'transfer' ? 'pointer' : 'unset'}} onClick={data.details.type === 'transfer' ? props.transferDetailsHandler : ()=>{}}>
            <td style={{width: 200}}>{`${fullyear}, ${date_hour}`}</td>
            <td style={{width: 200}}>{`${data.details.type.replace('_',' ')}`}</td>
            <td style={{textTransform: 'none'}} className={details_class}>{details}</td>
            <td className={classes.responsible}>{`${email} | ${firstName} ${lastName}`}</td>
        </tr>
    )
}

export default LogsTableItem;