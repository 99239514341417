import classes from './NavBar.module.scss'

import NavBarItem from '../NavBarItem/NavBarItem'
//import logo from '../../assets/images/foremedia_white.svg'
import foreshop from '../../assets/images/foreshop_logo.svg'
import small_logo from '../../assets/images/logoW.png'
import arrow from '../../assets/icons/double_arrow_left.svg'
import dashboard from '../../assets/icons/dashboard.svg'
import website_control from '../../assets/icons/website_control.svg'
import onair from '../../assets/icons/onair.svg'
import transactions from '../../assets/icons/transactions.svg'
import customers from '../../assets/icons/customers.svg'
import logs from '../../assets/icons/logs.svg'
import profile from '../../assets/icons/profile.svg'
import payments from '../../assets/icons/payments.svg'
import dollar from '../../assets/icons/dollar.svg'
import trasfer from '../../assets/icons/transfere.svg'

import ChangeCurrencyRate from '../ChangeCurrencyRate/ChangeCurrencyRate'

import { useSelector, useDispatch } from 'react-redux'
import { uiActions } from '../../store/ui-slice'
import { useEffect, useState } from 'react'

const NavBar = () => {

    const dispatch = useDispatch()

    const [showChangeCurrency, setShowChangeCurrency] = useState(false)

    const NAMES = useSelector(state => state.ui.language.values)
    const userNames = NAMES.sideBar
    const adminNames = NAMES.sideBarAdmin
    let isAdmin = useSelector(state => state.ui.showAdminInterface);
    let minimizeNavBar = useSelector(state => state.ui.minimizeNavBar)
    let showNavBarMobile = useSelector(state => state.ui.showNavBarMobile)
    let isImpersonating = useSelector(state => state.ui.isImpersonating)

    let width = window.screen.width

    useEffect(() => {

        if(width < 800 && width > 500) dispatch(uiActions.setMinimizeNavBar(true))

    })

    const minimizeToggle = () => {
        dispatch(uiActions.setMinimizeNavBar(!minimizeNavBar))
    }

    const closeMobileNavBar = () => {
        dispatch(uiActions.setShowNavBarMobile(false))
    }

    const toggleShowChangeCurrency = () => {
        setShowChangeCurrency(val => !val)
    }

    const returnToAdmin = () => {
        localStorage.removeItem('access_token')
        localStorage.removeItem('role')
        localStorage.setItem('access_token', localStorage.getItem('prev_token'))
        localStorage.setItem('role',localStorage.getItem('prev_role'))
        localStorage.removeItem('prev_token')
        localStorage.removeItem('prev_role')
        dispatch(uiActions.setNavigateToCustomers(true))
        dispatch(uiActions.setShowAdminInterface(true))
        dispatch(uiActions.setIsImpersonating(false))
        dispatch(uiActions.setIsLoggedIn(false))
        // redirect to login
        window.location.href = '/login/admin';
    }


    return (
        <>
        {showChangeCurrency && <ChangeCurrencyRate toggle={toggleShowChangeCurrency}/>}
        <div className={!minimizeNavBar ? classes.container : classes.container_minimized}>

            <div className={classes.logo_container} onClick={(minimizeNavBar && width > 800) ? minimizeToggle : () => {}}>
                {!minimizeNavBar && <div className={classes.logo}><img src={foreshop} alt='ForeMedia'/></div>}
                {!minimizeNavBar && <div className={classes.minimize} onClick={minimizeToggle}><img src={arrow} alt='<'/></div>}
                {minimizeNavBar && <div className={classes.small_logo}><img src={small_logo} alt='ForeMedia'/></div>}
            </div>

            <div className={classes.navbar_container}>

                {isAdmin && <>
                <NavBarItem image_src={dashboard} image_alt={adminNames.dashboard} name={adminNames.dashboard} route='dashboard'/>
                <NavBarItem image_src={website_control} image_alt={adminNames.websites} name={adminNames.websites} route='websites'/>
                <NavBarItem image_src={onair} image_alt={adminNames.onair} name={adminNames.onair} route='onair'/>
                {/* <NavBarItem image_src={transactions} image_alt={adminNames.transactions} name={adminNames.transactions} route='transactions'/> */}
                <NavBarItem image_src={payments} image_alt={adminNames.payments} name={adminNames.payments} route='payments'/>
                <NavBarItem image_src={customers} image_alt={adminNames.managing} name={adminNames.managing} route='customers'/>
                <NavBarItem image_src={logs} image_alt={adminNames.logs} name={adminNames.logs} route='logs'/>
                {!minimizeNavBar && <div className={classes.currency} onClick={toggleShowChangeCurrency}>{adminNames.change_currency}</div>}
                {minimizeNavBar && <div className={classes.currency} onClick={toggleShowChangeCurrency}><img src={dollar} alt='currency'/></div>}
                </>}

                {!isAdmin && <>
                <NavBarItem image_src={dashboard} image_alt={userNames.dashboard} name={userNames.dashboard} route='dashboard'/>
                <NavBarItem image_src={website_control} image_alt={userNames.websites} name={userNames.websites} route='websites'/>
                <NavBarItem image_src={transactions} image_alt={userNames.transactions} name={userNames.transactions} route='transactions'/>
                <NavBarItem image_src={payments} image_alt={adminNames.payments} name={adminNames.payments} route='payments'/>
                <NavBarItem image_src={profile} image_alt={userNames.profile} name={userNames.profile} route='profile'/>
                </>}
                <div className={classes.terms_of_service_container}>
                    <a target="_blank" href='/terms-of-service' className={classes.terms_of_service}>{adminNames.terms}</a>
                </div>
                <div className={classes.impersonate}>
                    <a target="_blank" href="https://docs.google.com/forms/u/0/d/1plBk-wHUFpmof66ngzC9_kuPRlJlkhgBUQS21NzzBsI/viewform?edit_requested=true" className={classes.blocked_help}>בקשה להעברת האתר</a>
                </div>

                {(isImpersonating && !minimizeNavBar) && <div className={classes.impersonate} onClick={returnToAdmin}>Return to Admin</div>}
                {(isImpersonating && minimizeNavBar) && <div className={classes.impersonate} onClick={returnToAdmin}><img src={trasfer} alt='return to admin'/></div>}
                
                {showNavBarMobile && <div className={classes.minimize_mobile} onClick={closeMobileNavBar}><img src={arrow} alt='<'/></div>}

            </div>
        </div>
        </>
    )
}

export default NavBar;