import classes from './SearchBar.module.scss'

import search from '../../assets/icons/search.svg'

const SearchBar = (props) => {

    return (
        <div className={classes.container}>
            <img src={search} alt='search'/>
            <input placeholder={props.placeholder} value={props.value} onChange={props.onChange} autoFocus={props.autoFocus}/>
        </div>
    )

}

export default SearchBar