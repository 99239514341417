import classes from './LoginLoad.module.scss'
import foreshop from '../../assets/images/foreshop_logo.png'

import LoadingTable from '../LoadingTable/LoadingTable'

const LoginLoad = () => {

    return (
        <div className={classes.container}>
            <div className={classes.main}>
                <div className={classes.top_container}>
                    <div className={classes.logo}><img src={foreshop} alt='ForeMedia'/></div>
                </div>
                <LoadingTable />
            </div>
        </div>
    )

}

export default LoginLoad;