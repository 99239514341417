/**
 * Accepts a string or a Date Object, and return a Date string with the format of DD/MM/YYYY
 * @param {String | Date} date 
 * @returns String (DD/MM/YYYY)
 */
export const fullYearParser = (date) => {

    if(date === '') return ''

    let temp = new Date(date)
    let month = temp.getMonth()+1
    let day = temp.getDate()

    if(month < 10) month = `0${month}`
    if(day < 10) day = `0${day}`

    return `${day}/${month}/${temp.getFullYear()}`
}
/**
 * Accept a number from 0-11 and return the month name in English
 * @param {number} number 
 * @returns String
 */
export const monthsParser = (number) => {
    let arr = ['January','February','March','April','May','June','July','August','September','October','November','December']

    // if (language === 'HEB') arr = ['ינואר','פרבואר','מרץ','אפריל','מאי','יוני','יולי','אוגוסט','ספטמבר','אוקטובר','נובמבר','דצמבר']

    if(number > arr.length) return arr[number % 11]

    else return arr[number]
}

/**
 * Accept a number from 1-12 and language code ('HEB' or 'ENG') and return the month name
 * according to month input.
 * @param {number} month 
 * @param {string} language 
 * @returns string
 */
export const monthsTranslator = (month,language) => {
    if(language === 'HEB') {
        switch(month) {
            case 1 : return 'ינואר'
            case 2 : return 'פרבואר'
            case 3: return 'מרץ'
            case 4: return 'אפריל'
            case 5: return 'מאי'
            case 6: return 'יוני'
            case 7: return 'יולי'
            case 8 : return 'אוגוסט'
            case 9: return 'ספטמבר'
            case 10: return 'אוקטובר'
            case 11: return 'נובמבר'
            case 12: return 'דצמבר'    
        }
    }
    if(language === 'ENG') {
        switch(month) {
            case 1 : return 'January'
            case 2 : return 'February'
            case 3: return 'March'
            case 4: return 'April'
            case 5: return 'May'
            case 6: return 'June'
            case 7: return 'July'
            case 8 : return 'August'
            case 9: return 'September'
            case 10: return 'October'
            case 11: return 'November'
            case 12: return 'December'    
        }
    }
    return month
}
/**
 * Accept a Date instance, and return a string with the hour and minutes (HH:MM)
 * @param {Date} date 
 * @returns string
 */
export const hoursMinutesParser = (date) => {

    let hours;
    let minutes;

    if(!(date instanceof Date)) return '00:00'

    else {
        if((date.getHours()+1) < 10) hours = `0${date.getHours()+1}`
        else hours = `${date.getHours()+1}`

        if(date.getMinutes() < 10) minutes = `0${date.getMinutes()}`
        else minutes = `${date.getMinutes()}`
    }

    return `${hours}:${minutes}`
}

/**
 * Accept a Status (string) and return it in a prettier format, example 'customer_active' -> 'Customer Active'
 * @param {String} status 
 * @returns string
 */
export const statusParser = (status) => {
    status = status.toLowerCase()

    if(status === 'customer_active') return 'Customer Active'
    if(status === 'company_active') return 'Company Active'
    if(status === 'company_inactive') return 'Company Inactive'
    if(status === 'on_pause') return 'On Pause'
    if(status === 'inactive') return 'Inactive'
    if(status === 'payment_issue') return 'Payment Issue'
    if(status === 'canceling') return 'Canceling'
    if(status === 'black_list') return 'Black List'
    if(status === 'customer_left') return 'Customer Left'

    return status
}
/**
 * Translate the statuses after using statusParser function.
 *
 * @param {String} status 
 * @param {String} language 
 * @returns String
 */
export const statusTranslator = (status,language) => {

    if(language === 'HEB') {
        if(status === 'all') return 'הכל'
        if(status === 'Customer Active') return 'ניהול שוטף'
        if(status === 'Company Active') return 'ניהול שוטף חברה'
        if(status === 'Company Inactive') return 'לא פעיל חברה'
        if(status === 'On Pause') return 'בהקפאה'
        if(status === 'Inactive') return 'לא פעיל'
        if(status === 'Payment Issue') return 'בעית תשלום'
        if(status === 'Canceling') return 'בתהליך ביטול'
        if(status === 'Black List') return 'רשימה שחורה'
        if(status === 'Customer Left') return 'לקוח עזב'
    }

    return status

}

/**
 * 
 * @param {*} confirmations Object
 * @param {*} declines Object
 * @returns Object
 */
export const OnAirStatusObjectsCombine = (confirmations, declines) => {

    return {
        ads: declines.ads ? !declines.ads : confirmations.ads,
        articles: declines.articles ? !declines.articles : confirmations.articles,
        campaigns: declines.campaigns ? !declines.campaigns : confirmations.campaigns,
        product: declines.product ? !declines.product : confirmations.product,
        seo: declines.seo ? !declines.seo : confirmations.seo,
        social: declines.social ? !declines.social : confirmations.social
    }

    
}

/**
 * Accept a url as a string and return it as a 'cleaned' format
 * for example: https://www.example.com/ -> example.com
 * @param {String} str 
 * @returns String
 */
export const urlCleaner = (str) => {
    const regex = new RegExp('(http|https)://')
    let clean_url = str.replace(regex, '').replace('www.','').replace('/', '')

    return clean_url
}

/**
 * Accept a string|number|boolean and return its boolean equivalent.
 * for example: 'true' -> true , 1 -> true
 * @param {string | boolean | number} value 
 * @returns 
 */
export const booleanParser = (value) => {
    return (value === 'true' || value === 1 || value === '1' || value === true)
}

/**
 * Recieve a string and push it to the user copyclipboard, in other words
 * when executed user will be able to paste the string given as argument.
 * @param {String} text 
 * @returns 
 */
export function copyToClipboard(text) {
    if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);

    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        }
        catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return prompt("Copy to clipboard: Ctrl+C, Enter", text);
        }
        finally {
            document.body.removeChild(textarea);
        }
    }
}

/**
 * This function accept the screen width, you can use the useWindowSize hook to get it,
 * and a boolean called navbarminimized which you can get from uiSlice in the global store.
 * return a correct width for the table.
 * @param {number} screenWidth 
 * @param {boolean} navbarminimized 
 * @returns number
 */
export const TableWidthHelper = (screenWidth,navbarminimized) => {
    const width_to_remove = navbarminimized ? 200 : 350

    return screenWidth - width_to_remove
}

/**
 * This function is the same as normal timeout, but enforcing a default execution time.
 * @param {Function} user_function 
 * @param {number} usertime 
 */
export const TimeOutHandler = (user_function,usertime) => {

    let time = usertime ? usertime : 2500

    setTimeout(() => {
        user_function()
    },time)
}

/**
 * payment Statuses are coming by default in english, incase language code is 'HEB' it will translate them into Hebrew
 * @param {String} status 
 * @param {String} language 
 * @returns String
 */
export const paymentsStatusesHandler = (status,language) => {
    switch(status) {
        case 'processing':
            if(language === 'HEB') return 'בתהליך'
            return status;
        case 'request accepted':
            if(language === 'HEB') return 'בקשה התקבלה'
            return status;
        case 'request rejected - wrong amount':
            if(language === 'HEB') return 'בקשה נדחתה - סכום שגוי'
            return status;
        case 'request declined':
            if(language === 'HEB') return 'בקשה לא התקבלה'
            return status;
        case 'payment process':
            if(language === 'HEB') return 'הועבר לתשלום'
            return status
        case 'paid':
            if(language === 'HEB') return 'שולם'
            return status;
        default:
            return status
            
    }
}

/** 
 * -- This Function is only used as a helper function to paymentAdminCSVHandler --
 * Accept a paymentRequest boolean (receipt_status, tax_recept_invoice, client_check), their matching refuse reason, and language code ('HEB','ENG')
 * and return the value needed to be represnted on the CSV according to those parameters.
 * @param {Boolean} bool 
 * @param {string} reason 
 * @param {string} language 
 * @returns boolean
 */
const paymentCSVBooleanParser = (bool,reason,language) => {

    if(language === 'HEB') {
        if(bool) return 'אושר'
        if(reason === '') return 'טרם הוחלט'
        if(!bool) return 'סורב'
    }
    else {
        if(bool) return 'Approved'
        if(reason === '') return 'No Status'
        if(!bool) return 'Declined'
    }

    return bool
}

/**
 * Recieve a array of paymentRequests and the desired language ('HEB' or 'ENG') return a array fitting the CSV maker
 * @param {Array} data 
 * @param {String} language 
 * @returns 
 */
export const paymentAdminCSVHandler = (data,language) => {

    let new_data;

    if(language === 'HEB') {
        new_data = data.map((obj) => {
            return {
                סטטוס: paymentsStatusesHandler(obj.request_status,language),
                'שם לקוח': `${obj.customer.firstName} ${obj.customer.lastName}`,
                נוצר: fullYearParser(obj.created),
                'סכום בש"ח': obj.amount.NIS,
                'אישור מסמך': paymentCSVBooleanParser(obj.receipt_status,obj.refuse_reason.receipt_status_reason,language),
                'אישור קבלה/חשבונית': paymentCSVBooleanParser(obj.receipt_tax_invoice,obj.refuse_reason.receipt_tax_invoice_reason,language),
                'אישור לקוח': paymentCSVBooleanParser(obj.client_check,obj.refuse_reason.client_check_reason,language),
                'תאריך אישור': obj.approved_date !== 'None' ? fullYearParser(obj.approved_date) : 'לא אושר',
                'חיוב זיכוי': obj.charge_refund ? 'חיובי' : '',
                'מספר חשבונית חיוב זיכוי': obj.charge_refund ? obj.charge_refund_details.invoice_id : ''
            }
        })
    }
    else {
        new_data = data.map((obj) => {
            return {
                Status: paymentsStatusesHandler(obj.request_status,language),
                'Client Name': `${obj.customer.firstName} ${obj.customer.lastName}`,
                'Created': fullYearParser(obj.created),
                'Amount NIS': obj.amount.NIS,
                'Receipt Status': paymentCSVBooleanParser(obj.receipt_status,language),
                'Tax Invoice Status': paymentCSVBooleanParser(obj.receipt_tax_invoice,language),
                'Client Check': paymentCSVBooleanParser(obj.client_check,language),
                'Approvment Data': obj.approved_date !== 'None' ? fullYearParser(obj.approved_date) : 'Not Approved',
                'Charge Refund': obj.charge_refund ? 'Positive' : '',
                'Charge Refund Invoice ID': obj.charge_refund ? obj.charge_refund_details.invoice_id : ''
            }
        })
    }

    return new_data

}

export const websiteControlCSVHandler = (data,language) => {

    let new_data = []
    let name = '---' 

    if(language === 'HEB') {
        new_data = data.map((item) => {
        const added = fullYearParser(item.added*1000)
        console.log(item)

        if(item.customers[0]) {
            name = `${item.customers[0]?.firstName} ${item.customers[0]?.lastName}`
        }   

            return {
                'הכנסות בשקלים': item.earned.NIS,
                'הכנסות בדולרים': item.earned.USD,
                'נכס': item.type === 'REGULAR' ? 'אתר' : 'פורטל',
                'נוסף': added,
                'סטטוס': statusTranslator(statusParser(item.status), 'HEB'),
                'רייטניר': item.retainer,
                'דומיין': item.domain,
                'לקוח': name
            }
        })
    }

    else {
        new_data = data.map((item) => {
            const added = fullYearParser(item.added*1000)
            console.log(item.customers)
            if(item.customers[0]) {
                name = `${item .customers[0]?.firstName} ${item.customers[0]?.lastName}`
            } 

            return {
                'Income NIS': item.earned.NIS,
                'Income USD': item.earned.USD,
                'Type': item.type,
                'Added': added,
                'Status': statusParser(item.status),
                'Retainer': item.retainer,
                'Domain': item.domain,
                'Customer': name
            }
        })
    }

    return new_data

}

/**
 * Generate Crisp chat
 */
export const generateChat = (role) => {

    if(role === 'CUSTOMER' || true) {
        const script = document.createElement("script")
        script.setAttribute("async","async")
        script.src = "https://client.crisp.chat/l.js"
        
        document.head.appendChild(script)
    }
    
}

/**
 * push data to crisp
 * @param {Object} data 
 */
export const crispPushUserData = (data) => {

    let phone = data.phone ? data.phone : ''

    if(phone.startsWith('05')) phone.replace('05','+972')
    if(phone.startsWith('5')) phone.replace('5','+972')
    if(phone.startsWith('972')) phone.replace('972','+972')

    const details = {
        nickname: `${data.lastName} ${data.firstName}`,
        email: data.email,
        phone: phone,
        company: data.companyName
    }

    for (let key of Object.keys(details)) {
        window.$crisp.push(["set", `user:${key}`, [details[ key ]]]);
    }

}

/**
 * Clear All timeouts, used to clear unrequired http requests on various search inputs
 */
export const clearAllTimeouts = () => {
    var highestTimeoutId = setTimeout(";");
    for (var i = 0 ; i < highestTimeoutId ; i++) {
        clearTimeout(i); 
    }
}
