import classes from './ChangeCurrecyRate.module.scss'

import CheckAnimation from '../CheckAnimation/CheckAnimation';
import FailAnimation from '../FailAnimation/FailAnimation'
import CustomButton from '../Customs/CustomButton';
import PopUpCard from '../Cards/PopUpCard/PopUpCard';

import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { userActions } from '../../store/user-slice';

const ChangeCurrencyRate = (props) => {

    const dispatch = useDispatch()
    const api_token = useSelector(state => state.user.token)

    const [Date, setDate] = useState('')
    const [newRate, setNewRate] = useState('')
    const [showCheckAnimation, setShowCheckAnimation] = useState(false)
    const [showFailAnimation, setShowFailAnimation] = useState(false)

    const API_URL = process.env.REACT_APP_API_URL

    const submitHandler = async (e) => {
        e.preventDefault()

        let response = await fetch(`${API_URL}/api/v2/settings/currency`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                newDate: Date,
                newRate: newRate
            })
        })

        const res_status = response.status
        response = await response.json()

        if(res_status === 200) {
            setShowCheckAnimation(true)
            dispatch(userActions.updateCurrentRate({
                lastUpdate: Date,
                rate: newRate
            }))
        }
        else { failHandler() }
        
    }

    const failHandler = () => {
        setShowFailAnimation(true)

        setTimeout(() => {
            setShowFailAnimation(false)
        },3000)
    }

    return (
        <PopUpCard toggle={props.toggle} header={'Change Currency Rate'}>
            {showCheckAnimation && <CheckAnimation />}
            {showFailAnimation && <FailAnimation />}
            <form className={classes.main_middle} onSubmit={submitHandler}>

                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{'Date'}</label>
                    <div className={classes.middle_item_input}>
                        <input className={classes.dateinput} required type='date' value={Date} onChange={(e) => setDate(e.target.value)}/>
                    </div>
                </div>

                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{'New Rate'}</label>
                    <div className={classes.middle_item_input}>
                        <input className={classes.input} required type='number' value={newRate} onChange={(e) => setNewRate(e.target.value)}/>
                    </div>
                </div>

                <div className={classes.buttons}>
                        <div className={classes.cancel} onClick={props.toggle}>Cancel</div>
                        <div ><CustomButton reverse color='red' name='Change Currency' type='submit'/></div>
                </div>

            </form>
        </PopUpCard>
    )

}

export default ChangeCurrencyRate;