const HeadersOnAir = (props) => {

    const names = props.names.campaigns.table

    return (
        <tr>
            <th>{names.status}</th>
            <th>{names.start}</th>
            <th>{names.confirmations}</th>
            <th>{names.customer}</th>
            <th>{names.retainer}</th>
            <th>{names.additionalNote}</th>
            <th>{names.delete}</th>
        </tr>
    )

}

export default HeadersOnAir;