import classes from './ConfirmationWindow.module.scss';

import CustomButton from '../../Customs/CustomButton';
import CheckAnimation from '../../CheckAnimation/CheckAnimation'
import FailAnimation from '../../FailAnimation/FailAnimation'
import PopUpCard from '../../Cards/PopUpCard/PopUpCard'

import { useState } from 'react';
import { useSelector } from 'react-redux';

const ConfirmationWindow = (props) => {

    const [reason, setReason] = useState('')
    const [showCheckAnimation, setShowCheckAnimation] = useState(false)
    const [showFailAnimation, setShowFailAnimation] = useState(false)
    const API_URL = process.env.REACT_APP_API_URL
    const api_token = useSelector(state => state.user.token)

    const data = props.data.data

    const onApprove = async () => {
       await updateStatusHandler(true)
    }

    const onDeny = async () => {
        await updateStatusHandler(false)
    }

    
    const updateStatusHandler = async (bool_val) => {

        let body = {
            campaignID: data.id,
            confirmation: bool_val,
            role: props.data.name,
        }

        if(!bool_val) body['reason'] = reason

        let response = await fetch(`${API_URL}/api/v2/campaigns/action`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify(body)  
        })
        const res_status = response.status
        response = await response.json()

        if(res_status === 200) {
            setShowCheckAnimation(true)
            props.setPageNumber(2)
            props.setPageNumber(1)
        }
        else {
            setShowFailAnimation(true)
            setTimeout(() => {
                setShowFailAnimation(false)
            }, 2000);
        }
    }

    return (
        <PopUpCard toggle={props.toggle} header={`Change Status - ${props.data.name}`}>
                <div className={classes.main_middle}>
                    {showCheckAnimation && <CheckAnimation />}
                    {showFailAnimation && <FailAnimation />}
                    <div className={classes.summary}>
                        <div className={classes.summary_item}>
                            <span>{`First Name:`}</span>
                            <span>{`${data.customer.firstName}`}</span>
                        </div>
                        <div className={classes.summary_item}>
                            <span>{`Last Name:`}</span>
                            <span>{`${data.customer.lastName}`}</span>
                        </div>
                        <div className={classes.summary_item}>
                            <span>{`Email:`}</span>
                            <span>{`${data.customer.email}`}</span>
                        </div>
                        <div className={classes.summary_item}>
                            <span>{`Domain`}</span>
                            <span>{`${data.domain}`}</span>
                        </div>
                    </div>

                    <div className={classes.middle_item}>
                        <label className={classes.middle_item_label}>{'Reason (Only required if denying)'}</label>
                        <div className={classes.middle_item_input}>
                                <input className={classes.dateinput} value={reason} onChange={(e) => setReason(e.target.value)}/>
                        </div>
                    </div>

                    <div className={classes.buttons}>
                        <div className={classes.customButton} onClick={onDeny}><CustomButton color='red' name='Deny' /></div>
                        <div className={classes.customButton} onClick={onApprove}><CustomButton color='green' name='Approve'/></div>
                    </div>
                    
                </div>
        </PopUpCard>
    )

}

export default ConfirmationWindow;