import classes from './EditAndSave.module.scss';

import { useState } from 'react';

/**
 * 
 * @param {String} note
 * @param {Function} onSubmit
 * @returns 
 */
const EditAndSave = (props) => {

    const [input, setInput] = useState(props.note ? props.note : '')

    return (
        <div className={classes.container}>
            <span onClick={() => props.onSubmit(input)}>{'Save & Close'}</span>
            <textarea placeholder='Type anything...' value={input} onChange={(e) => setInput(e.target.value)} autoFocus/>
        </div>
    )
}

export default EditAndSave;