import classes from './TransferDetails.module.scss'

import exit from '../../../assets/icons/exit.svg'

import GrayWindow from '../../Cards/GrayWindow/GrayWindow';
import ResponsiveTable from '../../Customs/ResponsiveTable/ResponsiveTable';
const TransferDetails = (props) => {

    const data = props.data.details.details

    console.log(data)

    return (
        <GrayWindow toggle={props.toggle}>
            <div className={classes.main_top}>
                <span>{'Transfer Details'}</span>
                <img src={exit} alt='X' onClick={props.toggle}/>
            </div>

            <div>
                <ResponsiveTable>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>NIS Rate</th>
                            <th>Type</th>
                        </tr>
                    </thead>
                    <tbody className={classes.table_data}>
                        {data.map((item,index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.added}</td>
                                    <td>{`₪ ${item.amount}`}</td>
                                    <td>{item.nisRate}</td>
                                    <td>{item.type}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </ResponsiveTable>
            </div>

        </GrayWindow>
    )

}

export default TransferDetails;