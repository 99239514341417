
const HeadersAllWebsites = (props) => {

    let headers_names = props.names

    return (
        <tr>
            <th>{headers_names.earnedNIS}</th>
            <th>{headers_names.earnedUSD}</th>
            <th>{headers_names.type}</th>
            <th>{headers_names.added}</th>
            <th style={{zIndex: 100}}>{headers_names.status}</th>
            <th>{headers_names.retainer}</th>
            <th style={{zIndex: 100}}>{headers_names.domain}</th>
            <th>{headers_names.customer}</th>
    </tr>
    )

}

export default HeadersAllWebsites