import classes from './WebsitesEditAndSave.module.scss'

import { useState } from 'react';
import { useSelector } from 'react-redux';

const WebsitesEditAndSave = (props) => {

    const [input, setInput] = useState(props.note ? props.note : '')
    const api_token = useSelector(state => state.user.token)
    const API_URL = process.env.REACT_APP_API_URL

    const submitHandler = async () => {
        
        let response = await fetch(`${API_URL}/api/v2/employees/websites`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                note: input,
                websiteID: props.websiteID
            })
        })

        response = response.json()
        props.setNote(input)
        props.close()
    }

    return (
        <div className={classes.container}>
            <span onClick={submitHandler}>{'Save & Close'}</span>
            <textarea placeholder='Type anything...' value={input} onChange={(e) => setInput(e.target.value)}/>
        </div>
    )
}

export default WebsitesEditAndSave