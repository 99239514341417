import classes from './AddNewCampaignDropDown.module.scss'

const AddNewCampaignDropDown = (props) => {
    let data = props.data;

    return (
        <div className={classes.container}>
            {data.map((item,index) => {
                return (
                    <div className={classes.item} key={index} onClick={() => props.onClick(item)}>
                        {item.email || item.domain}
                    </div>
                )
            })}
        </div>
    )
}

export default AddNewCampaignDropDown;