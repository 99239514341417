import classes from './Logs.module.scss'
import legend_classes from './LogsTableItem/LogsTableItem.module.scss'

import AmountSelect from '../AmountSelect/AmountSelect';
import Pagination from '../Pagination/Pagination';
import ResponsiveTable from '../Customs/ResponsiveTable/ResponsiveTable';

import LogsHeaders from './LogsHeaders';
import LogsTableItem from './LogsTableItem/LogsTableItem';
import TransferDetails from './TransferDetails/TransferDetails';

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
const Logs = () => {

    const names = useSelector(state => state.ui.language.values.logs)
    const api_token = useSelector(state => state.user.token)

    const [loadingResults, setLoadingResults] = useState(true)
    const [pageNumber, setPageNumber] = useState(1)
    const [numberOfResults, setNumberOfResults] = useState(20)
    const [numberOfPages, setNumberOfPages] = useState(5)

    const [Data, setData] = useState([])

    const [showTransferDetails, setShowTransferDetails] = useState(false)
    const [transferDetails, setTransferDetails] = useState([])

    const API_URL = process.env.REACT_APP_API_URL

    useEffect( async () => { 

        setLoadingResults(true)
        await importLogs()
        setLoadingResults(false)

    },[pageNumber,numberOfResults])

    useEffect(() => {
        setPageNumber(1)
    },[numberOfResults])

    const importLogs = async () => {

        const response = await fetch(`${API_URL}/api/v2/logs`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                items: numberOfResults,
                page: pageNumber
            })
        })

        let res_status = response.status
        const data = await response.json()

        if(res_status === 200) {
            setData(data.logs)
            setNumberOfPages(Math.ceil(data.entries/numberOfResults))
        }

    }

    const toggleShowTransferDetails = () => {setShowTransferDetails(val => !val)}

    const transferDetailsHandler = (data) => {
        setTransferDetails(data)
        setShowTransferDetails(true)
    }

    return (
        <>
        {showTransferDetails && <TransferDetails toggle={toggleShowTransferDetails} data={transferDetails}/>}
        <div className={classes.container}>
            <div className={classes.main}>

                <div className={classes.main_top}>
                    <div className={classes.main_title}>{names.title}</div>
                </div>

                <div className={classes.main_middle}>
                    <div className={classes.legend}>
                        <span className={legend_classes.impersonate}>{names.impersonate}</span>
                        <span className={legend_classes.customer_create}>{names.customer_create}</span>
                        <span className={legend_classes.website_status_green}>{names.website_active}</span>
                        <span className={legend_classes.website_status_red}>{names.website_others}</span>
                        <span className={legend_classes.onair}>{names.on_air}</span>
                        <span className={legend_classes.onair_delete}>{names.delete_on_air}</span>
                        <span className={legend_classes.transfer}>{names.transfer}</span>
                        <span className={legend_classes.transfer_delete}>{names.delete_transfer}</span>
                        <span className={legend_classes.transfer_move}>{names.move_transfer}</span>
                    </div>
                    <AmountSelect pageNumber={pageNumber} numberOfResults={numberOfResults} numberOfPages={numberOfPages} onChange={(e) => setNumberOfResults(e.target.value)}/>
                </div>

                <ResponsiveTable loadingResults={loadingResults}>
                    <thead>
                        <LogsHeaders names={names.headers}/>
                    </thead>
                    <tbody className={classes.table_data}>
                        {Data.map((item,index) => <LogsTableItem data={item} key={index} transferDetailsHandler={() => transferDetailsHandler(item)}/>)}
                    </tbody>
                </ResponsiveTable>

                <div className={classes.main_bottom}>
                    <AmountSelect pageNumber={pageNumber} numberOfResults={numberOfResults} numberOfPages={numberOfPages} onChange={(e) => setNumberOfResults(e.target.value)}/>
                    {numberOfPages > 1 && <Pagination pageNumber={pageNumber} setPageNumber={setPageNumber} numberOfPages={numberOfPages}/>}
                </div>

            </div>
        </div>
        </>
    )

}

export default Logs;