import classes from './WebsiteControlTableItem.module.scss';

import WebsitesEditAndSave from './../../WebsitesEditAndSave/WebsitesEditAndSave'
import CustomSelect from '../../Customs/CustomSelect/CustomSelect';

import papers from '../../../assets/icons/papers.svg'
import pencil from '../../../assets/icons/pencil.svg'
import history from '../../../assets/icons/history.svg'
import edit from '../../../assets/icons/settings.svg'

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { fullYearParser, copyToClipboard, statusTranslator } from "../../../utils/helper_functions"

const WebsitesControlTableItem = (props) => {

    const [showEditAndSave, setShowEditAndSave] = useState(false) 
    const [newStatus, setNewStatus] = useState(props.data.status) 

    const [Note, setNote] = useState(props.data.note)
    const api_token = useSelector(state => state.user.token)
    const language = useSelector(state => state.ui.language.code)
    const API_URL = process.env.REACT_APP_API_URL

    const navigate = useNavigate()

    const data = props.data
    const added = fullYearParser(data.added*1000)
    let type = 'Website'
    let typeclass = classes.website_text
    let customer = '---'

    const changeStatusHandler = async (e) => {

        const value = e.target.value

        let response = await fetch(`${API_URL}/api/v2/employees/websites/status`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                status: value,
                websiteID: data.websiteID,
                customerID: data.customers[0]?.customerID
            })
        })

        let res_status = response.status;
        response = response.json()

        if(res_status === 200) {
            setNewStatus(value)
        }
        else {
            if(data.customers.length === 0) {
                alert('This Website has no Customers')
            }
            else {
                alert('something went wrong')
            }
        }
        
    }

    if(data.type === 'PORTAL') {
        type = 'Portal'
        typeclass = classes.portal_text
    }
    if(data.customers[0]) customer = `${data.customers[0].firstName} ${data.customers[0].lastName}`

    return (
        <tr onMouseLeave={() => setShowEditAndSave(false)} key={props.forKey}>
            <td>{data.earned.NIS}</td>
            <td>{data.earned.USD}</td>
            <td className={typeclass}>{type}</td>
            <td>{added}</td>
            <td>
                <CustomSelect value={newStatus} onChange={changeStatusHandler} disabled={data.type === 'PORTAL'}>
                    <option value={'CUSTOMER_ACTIVE'}>{statusTranslator('Customer Active', language)}</option>
                    <option value={'COMPANY_ACTIVE'}>{statusTranslator('Company Active', language)}</option>
                    <option value={'COMPANY_INACTIVE'}>{statusTranslator('Company Inactive', language)}</option>
                    <option value={'ON_PAUSE'}>{statusTranslator('On Pause', language)}</option>
                    <option value={'INACTIVE'}>{statusTranslator('Inactive', language)}</option>
                    <option value={'PAYMENT_ISSUE'}>{statusTranslator('Payment Issue', language)}</option>
                    <option value={'CANCELING'}>{statusTranslator('Canceling',language)}</option>
                    <option value={'BLACK_LIST'}>{statusTranslator('Black List', language)}</option>
                </CustomSelect>
            </td>
            <td>{data.retainer}</td>
            <td className={classes.website}>
                <div className={classes.website_top}>
                    <a href={`https://www.${data.domain}`} target='_blank' rel="noreferrer">{data.domain}</a>
                    <div className={classes.icons}>
                        <img src={papers} alt='copy' onClick={() => copyToClipboard(data.domain)}/>
                        <img src={pencil} alt='edit' onClick={() => setShowEditAndSave(val => !val)}/>
                        <img src={history} alt='history' onClick={props.HistoryHandler}/>
                        <img src={edit} alt='edit' onClick={props.SettingsHandler}/>
                    </div>
                </div>
                {Note && 
                <div className={classes.note}>
                    <p>{`${Note}`}</p>
                </div>                
                }
                {showEditAndSave && <WebsitesEditAndSave websiteID={data.websiteID} note={Note} setNote={setNote} close={() => setShowEditAndSave(false)}/>}
            </td>
            <td>
                <div style={{cursor: 'pointer'}} onClick={() => navigate(`/user/customers?name=${data.customers[0].lastName}`, { replace: true})}>
                    {`${customer}`}
                </div>
            </td>
        </tr>
    )
}

export default WebsitesControlTableItem