import classes from './MyProfile.module.scss';

import profile_image from '../../assets/images/no_profile.svg'
import email_icon from '../../assets/icons/email_blue.svg'
import clock_icon from '../../assets/icons/clock_blue.svg'
import profile_icon from '../../assets/icons/profile_blue.svg'

import CustomButton from '../Customs/CustomButton';
import ChangeEmail from '../ChangeEmail/ChangeEmail';
import ChangePassword from '../ChangePassword/ChangePassword';
import BankDetails from '../BankDetails/BankDetails';

import {monthsTranslator} from '../../utils/helper_functions'

import { useSelector } from 'react-redux';
import { useState } from 'react';

const MyProfile = () => {

    const names = useSelector(state => state.ui.language.values.profile)
    const language = useSelector(state => state.ui.language.code)
    const user = useSelector(state => state.user)
    let email = user.email
    let date;

    const [showChangeEmail, setShowChangeEmail] = useState(false)
    const [showChangePassword, setShowChangePassword] = useState(false)
    const [showUpdateBankDetails, setShowUpdateBankDetails] = useState(false)

    const dateHandler = (date) => {

        let full_date = new Date(date);

        let month = monthsTranslator(full_date.getMonth(),language)
        let day = full_date.getDate()
        let year = full_date.getFullYear()
        return `${month} ${day}, ${year}`

    }

    const toggleChangeEmail = () => {
        setShowChangeEmail(val => !val)
    }

    const toggleChangePassword = () => {
        setShowChangePassword(val => !val)
    }

    const toggleShowUpdateBankDetails = () => {
        setShowUpdateBankDetails(val => !val)
    }

    date = dateHandler(user.added*1000)

    return (
        <>
        {showChangeEmail && <ChangeEmail toggle={toggleChangeEmail}/>}
        {showChangePassword && <ChangePassword toggle={toggleChangePassword}/>}
        {showUpdateBankDetails && <BankDetails update toggle={toggleShowUpdateBankDetails}/>}
        <div className={classes.container}>
            <div className={classes.main}>
                <div className={classes.profile}>

                    <div className={classes.profile_picture}>
                        <img src={profile_image} alt='profile'/>
                    </div>

                    <div className={classes.info}>
                    <div className={classes.info_item}>
                            <img src={profile_icon} alt='name'/>
                            <span>{`${user.firstName} ${user.lastName}`}</span>
                        </div>
                        <div className={classes.info_item}>
                            <img src={email_icon} alt='email'/>
                            <span>{email}</span>
                        </div>
                        <div className={classes.info_item}>
                            <img src={clock_icon} alt='email'/>
                            <span>{`${names.affiliate_since} ${date}`}</span>
                        </div>
                    </div>

                    <div className={classes.buttons}>
                        <div className={classes.button}><CustomButton name={names.changeMail} color='blue' onClick={toggleChangeEmail}/></div>
                        <div className={classes.button}><CustomButton name={names.changePassword} color='red' reverse onClick={toggleChangePassword}/></div>
                        {!user.isObserver && <div className={classes.button}><CustomButton name={names.updateBankAccount} color='blue' reverse onClick={toggleShowUpdateBankDetails}/></div>}
                    </div>

                </div>
            </div>
        </div>
        </>
    )
}

export default MyProfile