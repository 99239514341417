import classes from '../Children.module.scss';

import exit from '../../../assets/icons/exit.svg'

import CustomSelect from '../../Customs/CustomSelect/CustomSelect';
import SearchBar from '../../SearchBar/SearchBar';
import CustomButton from '../../Customs/CustomButton';
import LoadingTable from '../../LoadingTable/LoadingTable';
import CheckAnimation from '../../CheckAnimation/CheckAnimation';
import FailAnimation from '../../FailAnimation/FailAnimation';

import { TimeOutHandler } from '../../../utils/helper_functions';

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
const AddNewWebsite = (props) => {

    const [websiteList, setWebsiteList] = useState([])
    const [selectedWebsite, setSelectedWebsite] = useState('')
    const [searchResults, setSearchResults] = useState('')
    const [showSpinner, setShowSpinner] = useState(true)

    const [showCheckAnimation, setShowCheckAnimation] = useState(false)
    const [showFailAnimation, setShowFailAnimation] = useState(false)

    const api_token = useSelector(state => state.user.token)
    const API_URL = process.env.REACT_APP_API_URL

    useEffect(async () => {
        await fetchWebsiteList()
        setShowSpinner(false)
    },[])

    const fetchWebsiteList = async () => {

        let request_options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
        }

        const websites = await fetch(`${API_URL}/api/v2/campaigns/websites`,request_options)
        const data = await websites.json()

        setWebsiteList(data)
        setSelectedWebsite(data[0].domain)

    }

    const SearchResultHandler = (value) => {
        if(value === '') {
            setSelectedWebsite(websiteList[0].domain)
        }
        else {
            const filtered_list = websiteList.filter((item) => item.domain.startsWith(value))
            setSelectedWebsite(filtered_list[0].domain)
        }
        setSearchResults(value)
    }

    const NewWebsiteSubmitHandler = async() => {

        const website_data = websiteList.filter((website) => website.domain === selectedWebsite)[0]

        let response = await fetch(`${API_URL}/api/v2/employees/websites/status`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json','authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                websiteID: website_data.id,
                status: 'CUSTOMER_ACTIVE',
                customerID: props.customerID

            })
        })
        let response_status = response.status
        response = await response.json()

        if(response_status === 200) {
            console.log(website_data)
            //  props.updateData((item) => { return {...item, websites: [...item.websites,{...website_data,status: 'customer_active'}]} })
             setShowCheckAnimation(true)
             props.forceReload()
             TimeOutHandler(() => props.closeWindow(), 3000)
        }
        else {
            setShowFailAnimation(true)
            TimeOutHandler(() => setShowFailAnimation(false),3000)
        }
    }

    return (
        <div className={classes.container}>
            {showSpinner && <LoadingTable />}
            {!showSpinner &&
            <>
            <div className={classes.main_top}>
                    <span>{'Add New Website'}</span>
                    <img src={exit} alt='X' onClick={props.onClose}/>
            </div>

            <div className={classes.main_content}>
                {showCheckAnimation && <CheckAnimation />}
                {showFailAnimation && <FailAnimation />}
                <div className={classes.select}>
                    <SearchBar placeholder='Search by Domain' onChange={(e) => SearchResultHandler(e.target.value)} value={searchResults} autoFocus/>
                    <CustomSelect onChange={(e) => setSelectedWebsite(e.target.value)} value={selectedWebsite}>
                        {websiteList.map((item,index) => <option key={index} value={item.domain}>{item.domain}</option>)}
                    </CustomSelect>
                </div>

                <div className={classes.buttons}>
                    <div className={classes.cancel} onClick={props.onClose}>Cancel</div>
                    <div onClick={NewWebsiteSubmitHandler}><CustomButton name={'Add New Website'} color='blue' reverse/></div>
                </div>
            </div>
            </>            
            }

        </div>

    )
}

export default AddNewWebsite;