import './CheckAnimation.scss'

const CheckAnimation = (props) => {

    let container_class = 'container'

    if(props.height) {
        if(props.height === 105) container_class = 'container_105'
        if(props.height === 107) container_class = 'container_107'
        if(props.height === 110) container_class = 'container_110'
    }

    return (
        <div className={container_class}>
            <div className="success-checkmark">
                <div className="check-icon">
                    <span className="icon-line line-tip"></span>
                    <span className="icon-line line-long"></span>
                    <div className="icon-circle"></div>
                    <div className="icon-fix"></div>
                </div>
            </div>
        </div>
    )
}

export default CheckAnimation;