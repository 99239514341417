import classes from './TransactionsCalculator.module.scss'

import exit from '../../assets/icons/exit.svg';

import GrayWindow from '../Cards/GrayWindow/GrayWindow'
import LoadingTable from '../LoadingTable/LoadingTable';
import TransactionsCalculatorItem from './TransactionCalculatorItem/TransactionCalculatorItem';

import { fullYearParser } from '../../utils/helper_functions';

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
const TransactionsCalculator = (props) => {

    const api_token = useSelector(state => state.user.token)
    const API_URL = process.env.REACT_APP_API_URL

    const [total, setTotal] = useState([0])
    const [Data, setData] = useState([])
    const [showLoading, setShowLoading] = useState(true)

    useEffect( async () => {
        const response = await fetch(`${API_URL}/api/v2/users/transfers/list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                items: `${props.numberOfEntries}`,
                page: '1',
                paid: false
            })
        })

        if(response.status === 200) {
            let data = await response.json()
            setData(dataHandler(data.result))
            setShowLoading(false)
        }
       
    },[])

    const dataHandler = (data) => {
        let final_data = [[],[],[],[],[],[],[],[],[],[],[],[]] // 12 sub arrays representing months jan-dec

        for(let item of data) {
            let date = new Date(item.added*1000)
            final_data[date.getMonth()].push({
                value: item.NIS,
                date: fullYearParser(date),
                domain: item.domain
            })
        }
        
        final_data = final_data.map(arr => arr.reverse())

        return final_data
    }

    const updateTotal = (val) => {

        let vaL_index = total.indexOf(val)

        if(vaL_index === -1) {
            setTotal(total => [...total,val])
        }
        else {
            setTotal(total => total.filter(item => item !== val))
        }
    }


    return (
        <GrayWindow toggle={props.toggle}>
            <div className={classes.main}>
                <div className={classes.main_top}>
                    <span>{'Transcations Calculator'}</span>
                    <img src={exit} alt='X' onClick={props.toggle}/>
                </div>
                <div className={classes.total}>
                    {`Total ₪${total.reduce((prev,curr) => prev + curr).toFixed(2)}`}
                </div>
                <div className={classes.data}>
                    {showLoading && <LoadingTable />}
                    {!showLoading && Data.map((item) => <TransactionsCalculatorItem data={item} updateTotal={updateTotal}/>)}
                </div>
            </div>
        </GrayWindow>
    )

}

export default TransactionsCalculator