import classes from './BlockMessage.module.scss'

import foreshop from '../../assets/images/foreshop_logo.png'
const Terms = (props) => {



    return (
        <div className={classes.container}>
            <div className={classes.main} style={{maxHeight: "100vh", overflowY: "scroll", width: "auto"}}>
                <div className={classes.top_container}>
                    <div className={classes.logo}><img src={foreshop} alt='ForeMedia'/></div>
                    {/* <span className={classes.title}>PUBLISHER TERMS OF SERVICE</span> */}
                </div>

                <div className={classes.terms_container}>
            

                <p style={{textAlign:"right"}}><><>לקוחות נכבדים,</></></p>

                <p style={{textAlign:"right"}}><><>לאחר למעלה מ-3 שנים של פעילות, אנו נאלצים להודיע על הפסקת פעילות ניהול האתרים בחברה.<br />
                לצערנו, בעקבות שורה של נסיבות שהינן מעבר לכוחותינו, אנו נאלצים להפסיק את פעילות ניהול האתרים.<br />
                על מנת למשוך את אתרכם מניהול החברה לשרת ולעצור את תשלומי הריטיינר, אנא מלאו את הטופס שלהלן:</></></p>

                <p style={{textAlign:"right"}}><><><a href="https://docs.google.com/forms/u/0/d/1plBk-wHUFpmof66ngzC9_kuPRlJlkhgBUQS21NzzBsI/viewform">https://docs.google.com/forms/u/0/d/1plBk-wHUFpmof66ngzC9_kuPRlJlkhgBUQS21NzzBsI/viewform</a></></></p>

                <p style={{textAlign:"right"}}><><>גביה בעבור שירותי הריטיינר / קידום ממומן תיפסקת באופן גורף מהחודש הזה ללקוחות פעילים )תשלום הקידום<br />
                הממומן על החודש שחלף יגבה בתאריך 8.1 לכלל הלקוחות(, ניתן להפסיק פעילות באופן מיידי ובכך להימנע<br />
                מתשלום קידום ממומן על חודש יולי, בטופס:</></></p>

                <p style={{textAlign:"right"}}><><><a href="https://docs.google.com/forms/u/0/d/1plBk-wHUFpmof66ngzC9_kuPRlJlkhgBUQS21NzzBsI/viewform">https://docs.google.com/forms/u/0/d/1plBk-wHUFpmof66ngzC9_kuPRlJlkhgBUQS21NzzBsI/viewform</a></></></p>

                <p style={{textAlign:"right"}}><><>לקוחות אשר חייבים בתשלום ריטיינרים קודמים לתאריך זה, מתבקשים להסדיר חוב זה במסגרת קישור לתשלום<br />
                שמערכת הגבייה שלנו שולחת באופן אוטומטי ובכל אופן, ניתן למשוך את האתר באופן מיידי.</></></p>

                <p style={{textAlign:"right"}}><><>לקוחות בסטטוס הקפאה, נדרשים למלא את טופס העברת האתר גם הם בכדי למשוך את האתר משרתי החברה.</></></p>

                <p style={{textAlign:"right"}}><><>לקוחות ללא ידע טכנולוגי מספק אשר זקוקים לעזרה בהעברת האתר, אנא סמנו בטופס את האופציה:<br />
                &quot;הפסקת ניהול שוטף וקבלת פרטי הגישה לאתר, לשרת ולדומיין בכדי להעבירו לניהולי - דרושה לי עזרה של נציג<br />
                טכני מטעם חברת פורשופ להעביר את האתר לשרת משלי&quot;</></></p>

                <p style={{textAlign:"right"}}><><>אנו מודים לכם על שנים של פעילות משותפת ונחושים לסייע לכם בכל שנוכל בהעברת אתריכם לספקי שירות<br />
                אחרים או לשרתים של עצמכם.</></></p>

                <p style={{textAlign:"right"}}><><>שרתי החברה אשר מאחסנים את האתרים יהיו פעילים עד לתאריך 2022.8.31 לנוחיותכם ובכדי לאפשר זמן לאיתור<br />
                חלופה הולמת לאחסון, בכדי למנוע אי נעימות, אנא העבירו את אתרכם לשרת אחר לפני המועד הנל.</></></p>

                <p style={{textAlign:"right"}}><><>צוות התמיכה שלנו זמין לשירותכם בהעברת אתריכם על ידכם או ע&quot;י מי מטעמכם בכתובת האימייל:<br />
                request@foreshop.co.il</></></p>

                <p style={{textAlign:"right"}}><><>שלכם תמיד,<br />
                צוות פורשופ אחזקות בע&quot;מ.</></></p>


                </div>
            </div>
        </div>
    )
}

export default Terms