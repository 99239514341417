
import classes from './TranscationsHistory.module.scss'

import { fullYearParser } from '../../../../utils/helper_functions'
const TransactionsHistory = (props) => {

    const data = props.data

    return (
        <div className={classes.container}>
            <div className={classes.title}>Transactions History</div>
            <div className={classes.data}>
                {data.map((item,index) => {
                    return (
                        <div className={classes.data_item} key={index}>
                            <span>{item?.paidDate && fullYearParser(item.paidDate*1000)}</span>
                            <span>{item?.paidAmount && `₪ ${item.paidAmount.toFixed(2)}`}</span>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default TransactionsHistory;