import classes from './PaymentUser.module.scss'

import arrow_left from '../../assets/icons/arrow_left.svg'

import CustomButton from '../Customs/CustomButton'
import SearchBar from '../SearchBar/SearchBar'
import AmountSelect from '../AmountSelect/AmountSelect'
import Pagination from '../Pagination/Pagination'
import DragAndDrop from '../DragAndDrop/DragAndDrop'
import ResponsiveTable from '../Customs/ResponsiveTable/ResponsiveTable'
import IconContainerMedium from '../Customs/IconContainerMedium/IconContainerMedium' 

import PaymentUserHeader from './PaymentUserHeader'
import PaymentUserTableItem from './PaymentUserTableItem/PaymentUserTableItem'
import PaymentUserRequestHeader from './PaymentUserRequestHeader'
import PaymentUserRequestTableItem from './PaymentUserRequestTable/PaymentUserRequestTableItem'
import PaymentInvoiceContainer from '../PaymentInvoiceContainer/PaymentInvoiceContainer'
import BankDetails from '../BankDetails/BankDetails'
import RefuseReasons from './RefuseReasons/RefuseReasons'


import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'

import useWindowSize from '../../assets/hooks/useWindowSize';
import { TimeOutHandler } from '../../utils/helper_functions'

const PaymentUser = () => {

    const names = useSelector(state => state.ui.language.values.websitesContent.payments)
    const paymentDetails = useSelector(state => state.user.paymentDetails)
    const filledPaymentDetails = useSelector(state => state.user.filledPaymentDetails)
    const isEmployee = useSelector(state => state.user.isObserver)
    const api_token = useSelector(state => state.user.token)

    const [forceReload, setForceReload] = useState(false)

    const [loadingResults, setLoadingResults] = useState(false)
    const [showNewPaymentRequest, setShowNewPaymentRequest] = useState(false)
    const [showInvoiceContainer, setShowInvoiceContainer] = useState(false)
    const [showDragAndDrop, setShowDragAndDrop] = useState(false) 
    const [showRefuseReasons, setShowRefuseReasons] = useState(false)

    const[invoiceSource, setInvoiceSource] = useState(null)
    const [isTaxInvoice, setIsTaxInvoice] = useState(false)
    const [isReceiptReupload, setIsReceiptReupload] = useState(false)
    const [paymentRequestData, setPaymentRequestData] = useState({})
    const [refusedPayment, setRefusedPayment] = useState({})

    // const [searchResults, setSearchResults] = useState('')
    const [pageNumber, setPageNumber] = useState(1)
    const [numberOfResults, setNumberOfResults] = useState(20)
    const [numberOfPages, setNumberOfPages] = useState(5)

    const [Data, setData] = useState([])
    const [requestData, setRequestData] = useState([])

    const [checkAllCheckboxes, setCheckAllCheckboxes] = useState(false)
    const [totalMoney, setTotalMoney] = useState([0])
    const [totalTransfersList, setTotalTransfersList] = useState([])

    const [paymentRequestId, setPaymentRequestId] = useState(false)

    const API_URL = process.env.REACT_APP_API_URL
    let {width} = useWindowSize()

    useEffect(async () => {
        if(filledPaymentDetails) {
            setLoadingResults(true)
            await getUserPayments()
            setLoadingResults(false)
        }
    },[showNewPaymentRequest,pageNumber,numberOfResults,forceReload])

    // const searchResultsHandler = () => {
        
    // }

    const forceReloadHandler = () => {
        setForceReload(val => !val)
    }

    const getUserPayments = async () => {
        const response = await fetch(`${API_URL}/api/v2/getpaymentrequests`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                page: pageNumber,
                items: numberOfResults,
            })
        })
        const res_status = response.status
        const data = await response.json()

        if(res_status === 200) {
            setNumberOfPages(Math.ceil(data.entries/numberOfResults))
            setData(data.result)
        }
    }


    const createPaymentHandler = async () => {

        let sum = totalMoney.reduce((p,c) => p+c)

        if(paymentDetails.legal_status !== 'Private') {
            sum = (sum * 1.17)
        }

        let response = await fetch(`${API_URL}/api/v2/paymentrequest`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                amountNIS: sum.toFixed(2),
                transfers: totalTransfersList
            })
        })

        const res_status = response.status;
        response = await response.json()

        if(res_status === 200) {
            return response.paymentrequestId
        }
        else {
            return false
        }
    }

    const importPaymentRequestData = async () => {

        setLoadingResults(true)


        let response = await fetch(`${API_URL}/api/v2/users/transfersbydate`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`}})
        
        const res_status = response.status
        response = await response.json()  

        if(res_status === 200) {
            setRequestData(response.transfersbydate)
            TimeOutHandler(() => setLoadingResults(false), 1000)
        }

    }


    const toggleNewPaymentRequest = () => {
        setShowNewPaymentRequest(state => !state)
        importPaymentRequestData()
    }

    const toggleShowInvoiceContainer = () => {
        setShowInvoiceContainer(val => !val)
    }

    const viewInvoiceHandler = (image_src,data) => {
        toggleShowInvoiceContainer()
        setPaymentRequestData(data)
        setInvoiceSource(image_src)
    }

    
    const updateTotalMoney = (val) => {
        let vaL_index = totalMoney.indexOf(val)

        if(vaL_index === -1) {
            setTotalMoney(totalMoney => [...totalMoney,val])
        }
        else {
            setTotalMoney(totalMoney => totalMoney.filter(item => item !== val))
        }
    }

    const updateTransferList = (arr) => {

        const arrExist = arr.every(val => totalTransfersList.includes(val))

        // remove
        if(arrExist) {
            const copy_of_transfers = [...totalTransfersList];

            for(let i in arr) {
                let index = copy_of_transfers.indexOf(arr[i])
                copy_of_transfers.splice(index,1)
            }

            setTotalTransfersList(copy_of_transfers)
        }
        //add
        else {
            setTotalTransfersList(trasnfers => [...trasnfers,...arr])
        }
    }

    const resetTotalMoney = () => {setTotalMoney([0])}

    const resetTransferList = () => {setTotalTransfersList([])}
        
    const toggleDragAndDrop = () => {setShowDragAndDrop(val => !val)}

    const toggleRefuseReasons = () => {setShowRefuseReasons(val => !val)}

    const sendRequestHandler = () => {
        if(totalMoney.reduce((p,c) => p+c).toFixed(2) > 0) {
            setIsTaxInvoice(false)
            setIsReceiptReupload(false)
            setShowDragAndDrop(true)
        }
    }

    const onTaxInvoiceUpload = (id) => {
        setPaymentRequestId(id)
        setIsTaxInvoice(true)
        setShowDragAndDrop(true)
    }

    const onReceiptReupload = (id) => {
        setPaymentRequestId(id)
        setIsTaxInvoice(false)
        setIsReceiptReupload(true)
        setShowDragAndDrop(true)
    }

    const showRefuseReasonsHandler = (item) => {
        setRefusedPayment(item)
        setShowRefuseReasons(true)
    }


    return (
        <>
        {(!isEmployee && !filledPaymentDetails) && <BankDetails />}
        {showInvoiceContainer && <PaymentInvoiceContainer toggle={toggleShowInvoiceContainer} image_src={invoiceSource} data={paymentRequestData}/>}
        {showDragAndDrop && <DragAndDrop toggle={toggleDragAndDrop}
                            isTaxInvoice={isTaxInvoice}
                            paymentRequestId={paymentRequestId}
                            isReceiptReupload={isReceiptReupload} 
                            total={totalMoney.reduce((p,c) => p+c).toFixed(2)}
                            createPaymentHandler={createPaymentHandler}
                            onFinish={() => setShowNewPaymentRequest(false)}
                            forceReload={forceReloadHandler}/>}
                            
        {showRefuseReasons && <RefuseReasons data={refusedPayment} names={names} toggle={toggleRefuseReasons}/>}
        <div className={classes.container}>
            <div className={classes.main}>
                <div className={classes.main_top}>
                    <div className={classes.main_title}>{names.payments_requests}</div>
                    <div className={classes.main_buttons}>
                        {!showNewPaymentRequest && <div><CustomButton name={width > 500 ? names.new_payment_request : names.new_payment_request_short} color='red' reverse onClick={toggleNewPaymentRequest} disabled={!filledPaymentDetails}/></div>}
                        {showNewPaymentRequest && <>
                            <div  className={classes.goback} onClick={toggleNewPaymentRequest}><IconContainerMedium image_src={arrow_left} alt='<' color='red'/></div>
                            <div><CustomButton name={width > 500 ? names.send_request : names.send} color='red' reverse onClick={sendRequestHandler} disabled={totalMoney.reduce((p,c) => p+c) === 0}/></div></>
                        }
                    </div>
                </div>
                <div className={classes.main_middle}>
                    {/* <SearchBar placeholder={'Search by amount'} value={searchResults} onChange={searchResultsHandler}/> */}
                    <div></div>
                    {!showNewPaymentRequest && <AmountSelect pageNumber={pageNumber} numberOfResults={numberOfResults} numberOfPages={numberOfPages} onChange={(e) => setNumberOfResults(e.target.value)}/>}
                    {showNewPaymentRequest && <div className={classes.total}>{`${names.total} : ₪ ${totalMoney.reduce((p,c) => p+c).toFixed(2)}`}</div>}
                </div>
                {(width <= 900 && numberOfPages > 1 && !showNewPaymentRequest) &&
                <div className={classes.mobile_pagination}>
                    <Pagination pageNumber={pageNumber} setPageNumber={setPageNumber} numberOfPages={numberOfPages}/>
                </div>}
                
                <ResponsiveTable loadingResults={loadingResults}>
                    <thead>
                        {!showNewPaymentRequest && <PaymentUserHeader names={names}/>}
                        {showNewPaymentRequest && <PaymentUserRequestHeader names={names} setCheckAllCheckboxes={setCheckAllCheckboxes} resetTotalMoney={resetTotalMoney} totalMoney={totalMoney} totalTransfersList={totalTransfersList} resetTransferList={resetTransferList}/>}
                    </thead>
                    <tbody className={classes.table_data}>
                        {!showNewPaymentRequest && Data.map((item,index) => <PaymentUserTableItem 
                                                                                    data={item} key={index} names={names} 
                                                                                    showInvoice={viewInvoiceHandler} 
                                                                                    onUpload={onTaxInvoiceUpload} 
                                                                                    onShowRefuse={() => showRefuseReasonsHandler(item)}
                                                                                    onReceiptReupload={onReceiptReupload}/>)}
                        {showNewPaymentRequest && requestData.map((item,index) => <PaymentUserRequestTableItem key={index} item={item} checked={checkAllCheckboxes} updateTotalMoney={updateTotalMoney} updateTransferList={updateTransferList}/>)}
                    </tbody>
                </ResponsiveTable>

                {(width > 900 && !showNewPaymentRequest) &&
                <div className={classes.main_bottom}>
                    <AmountSelect pageNumber={pageNumber} numberOfResults={numberOfResults} numberOfPages={numberOfPages} onChange={(e) => setNumberOfResults(e.target.value)}/>
                    {numberOfPages > 1 && <Pagination pageNumber={pageNumber} setPageNumber={setPageNumber} numberOfPages={numberOfPages}/>}
                </div>
                }
            </div>
        </div>
        </>
    )
}

export default PaymentUser