import classes from './AddNewCampaign.module.scss'

import CustomButton from '../Customs/CustomButton'
import AddNewCampaignDropDown from './AddNewCampaignDropDown/AddNewCampaignDropDown'
import PopUpCard from '../Cards/PopUpCard/PopUpCard'
import CheckAnimation from '../CheckAnimation/CheckAnimation'
import FailAnimation from '../FailAnimation/FailAnimation'

import globe from '../../assets/icons/globe_gray.svg'
import customer from '../../assets/icons/single_person.svg'

import {TimeOutHandler} from '../../utils/helper_functions'

import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'

const AddNewCampaign = (props) => {

    const names = useSelector(state => state.ui.language.values.websitesContent.AddNewCampaign)
    const api_token = useSelector(state => state.user.token)

    const [domain, setDomain] = useState('')
    const [customerEmail, setCustomerEmail] = useState('')
    const [date, setDate] = useState()
    const [retainer, setRetainer] = useState(null)

    const [domainDate, setDomainDate] = useState({})
    const [customerData, setCustomerData] = useState({})

    const [customers, setCustmers] = useState([])
    const [renderedCustomers, setRenderedCustomers] = useState([])
    const [websites, setWebsites] = useState([])
    const [renderedWebsites, setRenderedWebsites] = useState([])

    const [showEmailDropdown, setshowEmailDropdown] = useState(false)
    const [showWebsiteDropdown, setShowWebsiteDropdown] = useState(false)
    const [showCheckAnimation, setShowCheckAnimation] = useState(false)
    const [showFailAnimation, setShowFailAnimation] = useState(false)

    const API_URL = process.env.REACT_APP_API_URL

    useEffect(async() => {
        let request_options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
        }

        const clients = await fetch(`${API_URL}/api/v2/campaigns/clients`,request_options)
        const clients_data = await clients.json()
        const websites = await fetch(`${API_URL}/api/v2/campaigns/websites`,request_options)
        const websites_data = await websites.json()

        setCustmers(clients_data)
        setRenderedCustomers(clients_data)
        setWebsites(websites_data)
        setRenderedWebsites(websites_data)

    },[])

    const submitHandler = async (e) => {
        e.preventDefault()

        let response = await fetch(`${API_URL}/api/v2/campaigns/new`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                customerID: customerData.id,
                retainer: retainer,
                startService: new Date(date),
                websiteID: domainDate.id
            })
        })
        const res_status = response.status
        response = await response.json()

        if(res_status === 200) {
            setShowCheckAnimation(true)
            TimeOutHandler(() => {
                props.forceReload()
                props.toggle()
            })
        }
        else {
            setShowFailAnimation(true)
            TimeOutHandler(() => setShowFailAnimation(false))
        }

    }
    
    const emailHandler = (email) => {
        if(email.length === 0 ){
            setshowEmailDropdown(false)
            setRenderedCustomers(customers)
        }
        else {
            setshowEmailDropdown(true)
            let filtered_customers = customers.filter((value) => value.email.includes(email))
            setRenderedCustomers(filtered_customers)
        }

        setCustomerEmail(email)
    }

    const domainHandler = (domain)  => {
        if(domain.length === 0) {
            setShowWebsiteDropdown(false)
            setRenderedWebsites(websites)
        }
        else {
            setShowWebsiteDropdown(true)
            let filtered_websites = websites.filter((value) => value.domain.includes(domain))
            setRenderedWebsites(filtered_websites)
        }

        setDomain(domain)
    }

    const retainerHandler = (retainer) => {
        let numbers_regex = new RegExp('^[0-9]*$')

        if(!numbers_regex.test(retainer)) {
            setRetainer(0)
        }
        else {
            setRetainer(retainer)
        }
    }

    const dropdownEmailClickHandler = (item) => {
        setCustomerData(item)
        setCustomerEmail(item.email)
        setshowEmailDropdown(false)
    }
   
    const dropdownWebsiteClickHandler = (item) => {
        setDomainDate(item)
        setRetainer(item.retainer)
        setDomain(item.domain)
        setShowWebsiteDropdown(false)
    }

    return (
        <PopUpCard toggle={props.toggle} header={names.title}>
            {showCheckAnimation && <CheckAnimation />}
            {showFailAnimation && <FailAnimation />}
            <form className={classes.main_middle} onSubmit={submitHandler}>
                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{names.website_name}</label>
                        <div className={classes.middle_item_input}>
                            <img src={globe} alt='globe'/>
                            <input placeholder='domain.com' required value={domain} onChange={(e) => domainHandler(e.target.value)}/>
                            {showWebsiteDropdown && <AddNewCampaignDropDown data={renderedWebsites} onClick={dropdownWebsiteClickHandler}/>}
                        </div>
                </div>

                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{names.customer}</label>
                        <div className={classes.middle_item_input}>
                            <img src={customer} alt='customer'/>
                            <input placeholder='email@email.com' required type='email' value={customerEmail} onChange={(e) => emailHandler(e.target.value)}/>
                            {showEmailDropdown && <AddNewCampaignDropDown data={renderedCustomers} onClick={dropdownEmailClickHandler}/>}
                        </div>
                </div>

                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{names.date}</label>
                        <div className={classes.middle_item_input}>
                            <input className={classes.dateinput}required type='date' value={date} onChange={(e) => setDate(e.target.value)}/>
                        </div>
                </div>

                <div className={classes.middle_item}>
                    <label className={classes.middle_item_label}>{names.retainer}</label>
                    <div className={classes.middle_item_input}>
                            <img src={customer} alt='customer'/>
                            <input placeholder='Number' required min={0} type='number' value={retainer} onChange={(e) => retainerHandler(e.target.value)}/>
                    </div>
                </div>

                <div className={classes.buttons}>
                        <div className={classes.cancel} onClick={props.toggle}>{names.cancel}</div>
                        <div className={classes.customButton}><CustomButton reverse color='red' name={names.add} type='submit'/></div>
                </div>

            </form>
        </PopUpCard>
    )
}

export default AddNewCampaign