
const PaymentAdminHeaders = (props) => {

    const names = props.names
    
    return (
        <tr>
            <th >{names.status}</th>
            <th >{names.client_name}</th>
            <th >{names.date}</th>
            <th >{'₪ ' + names.amount}</th>
            <th >{names.receipt_status}</th>
            <th >{names.receipt_status}</th>
            <th >{names.client_check}</th>
            <th >{names.receipt_tax}</th>
            <th >{names.receipt_tax}</th>
            <th >{names.charge_refund}</th>
            <th >{names.notes}</th>
        </tr>
    )
}

export default PaymentAdminHeaders;