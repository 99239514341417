import classes from './UserAgreement.module.scss'

import foreshop from '../../assets/images/foreshop_logo.png'
import openfile from '../../assets/icons/openfile.svg'

import terms from '../../assets/documents/Terms.pdf'
import policy from '../../assets/documents/Policy.pdf'

import CustomButton from '../Customs/CustomButton'
import CheckAnimation from '../CheckAnimation/CheckAnimation'

import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { userActions } from '../../store/user-slice'
import { TimeOutHandler } from '../../utils/helper_functions'
const UserAgreement = (props) => {

    const [checked,SetChecked] = useState(false)
    const [showCheckAnimation, setShowCheckAnimation] = useState(false)

    const api_token = useSelector(state => state.user.token)
    const API_URL = process.env.REACT_APP_API_URL
    const dispatch = useDispatch()

    const ClickHandler = async() => {

        if(checked) {

            let response = await fetch(`${API_URL}/api/v2/customers/iagree`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${api_token}`},
                body: JSON.stringify({ iagree: true})
            })

            const response_status = response.status;
            response = await response.json()

            if(response_status === 200) {
                setShowCheckAnimation(true)
                TimeOutHandler(() => {
                    dispatch(userActions.SetAgreement(true))
                    props.showAgreement()
                })
            }

        }
    }
    

    return (
        <div className={classes.container}>
            
            <div className={classes.main}>
                {showCheckAnimation && <CheckAnimation />}
                <div className={classes.top_container}>
                    <div className={classes.logo}><img src={foreshop} alt='ForeMedia'/></div>
                </div>

                <div className={classes.title}>
                    אשר תנאי שימוש ומדיניות פרטיות
                </div>

                <div className={classes.buttons}>
                    <div className={classes.button} onClick={() => window.open(terms, "_blank")}>
                        <div>תנאי שימוש</div>
                        <img src={openfile} alt='open'/>
                    </div>
                    <div className={classes.button} onClick={() => window.open(policy, "_blank")}>
                        <div>מדיניות פרטיות</div>
                        <img src={openfile} alt='open'/>
                    </div>
                </div>

                <div className={classes.approve}>
                    <div>אני מאשר כי קראתי ואני מסכים לתנאי השימוש ומדיניות הפרטיות</div>
                    <input type='checkbox' onChange={(e) => SetChecked(e.target.checked)}/>
                </div>

                <CustomButton name='אישור' color='blue' reverse onClick={ClickHandler}/>
            </div>
        </div>
    )
}

export default UserAgreement;
