import classes from './StatusDetails.module.scss';

import PopUpCard from '../../Cards/PopUpCard/PopUpCard'

const StatusDetails = (props) => {

    const data = props.data
    const declines = data.declines

    return (
        <PopUpCard toggle={props.toggle} header={`Decline Reasons - ${data.customer.email}`}>

            <div className={classes.main}>
                {declines.ads &&
                <div className={classes.main_item}>
                    <span className={classes.main_title}>Ads</span>
                    <span className={classes.main_reason}>{declines.ads}</span>
                </div>}
                {declines.articles &&
                <div className={classes.main_item}>
                    <span className={classes.main_title}>Articles</span>
                    <span className={classes.main_reason}>{declines.articles}</span>
                </div>                
                }
                {declines.campaigns &&
                <div className={classes.main_item}>
                    <span className={classes.main_title}>Campaigns</span>
                    <span className={classes.main_reason}>{declines.campaigns}</span>
                </div>                
                }
                {declines.product &&
                <div className={classes.main_item}>
                    <span className={classes.main_title}>Product</span>
                    <span className={classes.main_reason}>{declines.product}</span>
                </div>                
                }
                {declines.seo && 
                <div className={classes.main_item}>
                    <span className={classes.main_title}>Seo</span>
                    <span className={classes.main_reason}>{declines.seo}</span>
                </div>                
                }
                {declines.social &&
                 <div className={classes.main_item}>
                    <span className={classes.main_title}>Social</span>
                    <span className={classes.main_reason}>{declines.social}</span>
                </div>               
                }
            </div>
        </PopUpCard>
    )

}

export default StatusDetails;