
import classes from './OnAirTableHistory.module.scss'

import { fullYearParser } from "../../utils/helper_functions"
const OnAirTableItemHistory = (props) => {

    const data = props.data
    const date_started = fullYearParser(data.start)
    const data_created = fullYearParser(data.created)
    let note = data.additionalNote

    if(note === null) note = 'Empty'

    return (
        <tr>
            <td>{date_started}</td>
            <td className={classes.email}>{data.customer.email}</td>
            <td className={classes.retainer}>{data.retainer}</td>
            <td>{note}</td>
            <td>{data.domain}</td>
            <td>{data_created}</td>
            <td></td>
        </tr>
    )
}

export default OnAirTableItemHistory