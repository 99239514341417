
import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
    name: 'user',
    initialState: {
            token: null,
            isAdmin: false,
            isModerator: false,
            isObserver: false,
            lang: 'ENG',
            firstName: '',
            lastName: '',
            email: '',
            totalWebsites: 0,
            totalCustomers: 0,
            currentRate: {
                lastUpdate: '2000-00-00',
                rate: '4.999'
            },
            permissions: {},
            totalEmployees: 0,
            totalTransfers: 0,
            totalBalance: 0,
            totalPaid: 0,
            totalByMonth: [],
            agreement: false,
            filledPaymentDetails: false,
            companyName: 'none',
            websites: [],
            paymentDetails: {
                bank_account_owner: 'Test Tester',
                bank_account_number: 'ForeShop Test',
                bank: 'בנק פורשופ (00)',
                bank_branch: '0',
                bank_address: 'ראול ונלדברג 4',
                legal_status: 'Company',
            },
            declinedPayments: [],
    },
    reducers: {
        setUser(state, action) {

            console.log(action.payload.paymentDetails)

            state.lang = action.payload.lang
            state.firstName = action.payload.firstName
            state.lastName = action.payload.lastName
            state.added = action.payload.added
            state.email = action.payload.email
            state.totalWebsites = action.payload.totalWebsites
            state.totalCustomers = action.payload.totalCustomers
            state.currentRate = action.payload.currentRate
            state.permissions = action.payload.permissions
            state.totalEmployees = action.payload.totalEmployees
            state.totalTransfers = action.payload.totalTransfers
            state.totalBalance = action.payload.totalBalance
            state.totalPaid = action.payload.totalPaid
            state.totalByMonth = action.payload.totalByMonth
            state.agreement = action.payload.agreement
            state.companyName = action.payload.companyName
            state.websites = action.payload.websites || [{domain: "example.com",type: "REGULAR",added: Date.now(),earned: 0}]
            state.paymentDetails = action.payload.paymentDetails
            state.filledPaymentDetails = action.payload.filledPaymentDetails 
            state.declinedPayments = action.payload.declined_payments

        },
        setIsAdmin(state, action) {
            state.isAdmin = action.payload
        },
        setIsModerator(state, action) {
            state.isModerator = action.payload
        },
        setIsObserver(state, action) {
            state.isObserver = action.payload
        },
        setToken(state, action) {
            state.token = action.payload
        },
        setEmail(state, action) {
            state.email = action.payload
        },
        updateCurrentRate(state, action) {
            state.currentRate = action.payload
        },
        SetAgreement(state, action) {
            console.log(action.payload)
            state.SetAgreement = action.payload
        },
        setPaymentsDetails(state, action) {
            state.paymentDetails = action.payload
        },
        setfilledPaymentDetails(state, action) {
            state.filledPaymentDetails = action.payload
        }
    }
})

export const userActions = userSlice.actions
export default userSlice