import classes from './Dashboard.module.scss'
import { useSelector } from 'react-redux'
import { useState } from 'react'

import balance from '../../assets/icons/balance.svg'
import withdrawn from '../../assets/icons/withdrawn.svg'
import shekel from '../../assets/icons/shekel.svg'

import DashboardCard from "../Cards/DashboardCard";
import LineChart from '../Charts/LineChart/LineChart'
import BarChart from '../Charts/BarChart/BarChart'
// import DemoChart from '../Charts/DemoChart/DemoChart'

const Dashboard = () => {

    const names = useSelector(state => state.ui.language.values)
    const userData = useSelector(state => state.user)
    const showAdminInterface = useSelector(state => state.ui.showAdminInterface)
    const api_token = useSelector(state => state.user.token)

    const [lineChartLabels, setLineChartLabels] = useState(userData.totalByMonth.map((item) => Object.keys(item)).reverse())
    const [lineChartData, setLineChartData] = useState([userData.totalByMonth.map((item) => Object.values(item)[0].NIS).reverse()])
    const [barChartLabels, setBarChartLabes] = useState(showAdminInterface ? [] : userData.websites.map((value) => value.domain))
    const [barChartData , setBarChartData] = useState(showAdminInterface ? [] : [userData.websites.map((value) => value.earned)])

    let revenue_value = userData.totalBalance
    let withdrawn_value = userData.totalPaid
    let balance_value = userData.totalBalance - userData.totalPaid

    let linechart_title = names.graphs.incomeMonths
    let barchart_title = names.graphs.webSiteIncome
    // let demochart_title = names.graphs.analytics


    return (
        <div className={classes.container}>
            <div className={classes.top_container}>
                <DashboardCard image={shekel} title={names.revenue} value={revenue_value.toFixed(2)}/>
                <DashboardCard image={withdrawn} title={names.withdrawn} value={withdrawn_value.toFixed(2)}/>
                <DashboardCard image={balance} title={names.notPaid} value={balance_value.toFixed(2)}/>
            </div>

            <div className={classes.charts_container}>
                <div className={classes.chart}>
                    <span className={classes.chart_title}>{linechart_title}</span>
                    <LineChart title='linechart' isAdmin={showAdminInterface} serverData={{
                        labels: lineChartLabels,
                        series: lineChartData
                    }}/>
                </div>

                {!showAdminInterface && 
                <div className={classes.chart}>
                    <span className={classes.chart_title}>{barchart_title}</span>
                    <BarChart serverData={{
                        labels: barChartLabels,
                        series: barChartData
                    }}/>
                </div>}

                {/* {!showAdminInterface && 
                <div className={classes.chart}>
                    <span className={classes.chart_title}>{demochart_title}</span>
                    <DemoChart access_token={api_token}></DemoChart>
                </div>} */}
                
            </div>
        </div>
    )
}

export default Dashboard;