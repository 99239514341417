import classes from './TopBarMobile.module.scss'

import names from '../../utils/names_langs'
import CustomSelect from '../Customs/CustomSelect/CustomSelect';
import IconContainerMedium from '../Customs/IconContainerMedium/IconContainerMedium';
import logo from '../../assets/images/foremedia_white.svg'

import translate from '../../assets/icons/translate.svg'
import logout from '../../assets/icons/logout.svg'
import mobilenav_icon from '../../assets/icons/mobilenav.svg'

import { useSelector, useDispatch } from 'react-redux';
import {uiActions} from '../../store/ui-slice'
import { useNavigate } from 'react-router';

import { booleanParser } from '../../utils/helper_functions';

const TopBarMobile = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const language_code = useSelector(state => state.ui.language.code)
    const user = useSelector(state => state.user)
    const isAdmin = user.isAdmin
    const showAdminInterface = useSelector(state => state.ui.showAdminInterface)
    const currentRate = user.currentRate

    const toggleLanguageHandler = () => {
        if(language_code === 'ENG') {
            dispatch(uiActions.toggleLanguage({
                values: names.HEB,
                name: 'hebrew',
                code: 'HEB'
            }))
        }
        if(language_code === 'HEB') {
            dispatch(uiActions.toggleLanguage({
                values: names.ENG,
                name: 'english',
                code: 'ENG'
            }))
        }
    }

    const toggleAdminHandler = (e) => {
        dispatch(uiActions.setShowAdminInterface(booleanParser(e.target.value)))
    }

    const logoutHandler = () => {

        if('$crisp' in window){
            window.$crisp.push(["do", "session:reset"]);
            window.$crisp = []
        }

        localStorage.removeItem('access_token')
        localStorage.removeItem('role')
        dispatch(uiActions.setIsLoggedIn(false))
        navigate('/login',{ replace: true })
    }

    const showNavBarMobile = () => {
        dispatch(uiActions.setShowNavBarMobile(true))
    }

    return (
        <div className={classes.container}>

            <div className={classes.top}>
                <div className={classes.image_container}>
                    <img src={logo} alt='foremedia'/>
                </div>

                <div className={classes.top_buttons}>
                    {isAdmin && 
                    <CustomSelect onChange={toggleAdminHandler} topbarmobile>
                        <option value={true}>AD</option>
                        <option value={false}>USR</option>
                    </CustomSelect>
                    }
                    <div onClick={toggleLanguageHandler}>
                        <IconContainerMedium color='red' image_src={translate} alt='translate'/>
                    </div>
                    <div onClick={logoutHandler}>
                        <IconContainerMedium color='black' image_src={logout} alt='logout'/>
                    </div>

                </div>
            </div>

            <div className={classes.bottom}>
                <div className={classes.navbar} onClick={showNavBarMobile}>
                    <img src={mobilenav_icon} alt='menu'/>
                    <span>Menu</span>
                </div>
                <div>
                    <div className={classes.convertion}>{`1$ = ${currentRate.rate} NIS`}</div>
                    <div className={classes.convertion_date}>{'שער דולר ממוצע'}</div>
                </div>
            </div>

        </div>
    )

}

export default TopBarMobile