import classes from './ViewCustomerTranscations.module.scss'

import exit from '../../assets/icons/exit.svg'

import GrayWindow from '../Cards/GrayWindow/GrayWindow';
import ResponsiveTable from '../Customs/ResponsiveTable/ResponsiveTable';
import CustomButton from '../Customs/CustomButton';
import CustomSelect from '../Customs/CustomSelect/CustomSelect';
import CheckAnimation from '../CheckAnimation/CheckAnimation';
import FailAnimation from '../FailAnimation/FailAnimation';
import DeleteConfirmation from '../Cards/DeleteConfirmation/DeleteConfirmation';

import CustomerTranscationsTableItem from './CustomerTranscationsTableItem';
import StackData from './StackData';

import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { fullYearParser, TimeOutHandler } from '../../utils/helper_functions';

const ViewCustomerTranscations = (props) => {

    const data = props.data

    const names = useSelector(state => state.ui.language.values.transfersContent)
    const api_token = useSelector(state => state.user.token)
    const isAdmin = useSelector(state => state.user.isAdmin)
    const API_URL = process.env.REACT_APP_API_URL

    const [showStack, setShowStack] = useState(false)
    const [showCheckAnimation, setShowCheckAnimation] = useState(false)
    const [showFailAnimation, setShowFailAnimation] = useState(false)
    const [showDeleteConfirmation, setShowDeletConfirmation] = useState(false)

    const [loadingResults, setLoadingResults] = useState(true)

    const [transferData, setTransferData] = useState([])
    const [stackData, setStackData] = useState([])
    const [stackValue, setStackValue] = useState(0)

    const [selectAll, setSelectAll] = useState(false)

    let userSelectedWebsite = (props.data.websites && props.data.websites.length > 0)?props.data.websites[0].domain:"No Website" 
    const [selectedWebsite, setSelectedWebsite] = useState(userSelectedWebsite)
    const [transcationToDelete, setTransactionToDelete] = useState({})

    useEffect( async () => {

        const response = await fetch(`${API_URL}/api/v2/users/transfers/list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                page: 1,
                items: 1000,
                customerID: props.data.customerID
            })
        })

        const data = await response.json()

        if(response.status === 200) {
            setTransferData(data.result === null ? [] : data.result)
            setLoadingResults(false)
        }
        
    },[])

    const deleteHandler = async () => {

        if(!isAdmin) {
            alert('You dont have permissions to do this action')
            return;
        }

        if(transcationToDelete.paid || transcationToDelete.paymentRequested) {
            alert('לא יכול למחוק העברה שהתבקשה לתשלום')
            setShowDeletConfirmation(false)
            return;
        }

        let response = await fetch(`${API_URL}/api/v2/employees/transfers`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                transfers: [transcationToDelete.transferID]
            })
        })

        const res_status = response.status
        response = await response.json()

        if(res_status === 200) {
            const newData = transferData.filter((transfer) => transfer.transferID !== transcationToDelete.transferID)
            setTransferData(newData)
            setShowDeletConfirmation(false)
        }
        
    }

    const mark_as_paid = async () => {
        return submitHandler("PAID");
    }
    const mark_as_not_paid = async () => {
        return submitHandler("NOT_PAID");
    }

    const submitHandler = async (reset=false) => {

        if(!isAdmin) {
            alert('You dont have permissions to do this action')
            return;
        }

        // method PUT to add DELETE to delete
        let method = 'PUT';
        let updateTransaction = String(false);
        if(reset!==false) {
            method = 'DELETE';
            if(reset === "PAID") {
                updateTransaction = "paid";
            }else if (reset === "NOT_PAID") {
                updateTransaction = "not_paid";
            }
        }

        const stackIds = stackData.map((item) => item.transferID)
        const website = data.websites.filter((item) => item.domain === selectedWebsite)[0]

        if(stackIds.length > 0) {
            let response = await fetch(`${API_URL}/api/v2/employees/transfers`, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${api_token}`},
                body: JSON.stringify({
                    transfers: stackIds,
                    updateTransaction: updateTransaction,
                    websiteID: website.websiteID
                })
            })

            const res_status = response.status
            response = response.json()

            if(res_status === 200) {
                setShowCheckAnimation(true)
                TimeOutHandler(() => props.toggle())
            }
            else {
                setShowFailAnimation(true)
                TimeOutHandler(() => setShowFailAnimation(false))
            }
        }
        else {
            alert('You need to have atleast one item in stack to submit')
        }

    }

    const addWebsiteToStackHandler = (data) => {

        if(!isAdmin) {
            alert('You dont have permissions to do this action')
            return;
        }

        for(let i = 0; i<stackData.length; i++) {
            if(stackData[i].transferID === data.transferID) {
                setStackData(items => items.filter(item => item.transferID !== data.transferID))
                setStackValue(val => val-data.NIS)
                return
            }
        }

        setStackData(items => [...items,data])
        setStackValue(val => val+data.NIS)
    }

    const addRemoveAllTranscations = () => {

        if(!isAdmin) {
            alert('You dont have permissions to do this action')
            return;
        }

        let stack_value = 0

        if(stackData.length === transferData.length) {
            setStackData([])
            setStackValue(stack_value)
            setSelectAll(false)
        }
        else {
            setStackData(transferData)
            transferData.forEach((item) => stack_value+=item.NIS)
            setStackValue(stack_value)
            setSelectAll(true)
        }
    }
    
    // const toggleShowStack = () => {
    //     setShowStack(val => !val)
    // }

    const addToStackByDate = (item) => {

        if(!isAdmin) {
            alert('You dont have permissions to do this action')
            return;
        }

        const _date = new Date(item.added*1000)
        const year = _date.getFullYear()
        const month = _date.getMonth()

        const filterd_list = transferData.filter((item) => {
            const item_date = new Date(item.added*1000)
            const item_month = item_date.getMonth()
            const item_year = item_date.getFullYear()

            if(month === item_month && year === item_year) {
                return item.transferID
            }
        })

        filterd_list.forEach((item) => {
            addWebsiteToStackHandler(item)
        })
        
    }

    return (
        <GrayWindow toggle={props.toggle}>
            <div className={classes.container}>
            <div className={classes.main_top}>
                <span>{`${names.view} ${names.transactions} - ${data.firstName} ${data.lastName} `}</span>
                <span>{`סכום העברות: ₪${Math.abs(stackValue.toFixed(2))} `}</span>
                <div style={{display: 'flex', gap: '2rem', alignItems: 'center'}}>
                    <span>{names.select_website}</span>
                    <CustomSelect value={selectedWebsite} onChange={(e) => setSelectedWebsite(e.target.value)}>
                        {data.websites.map((website) => <option key={`${website.domain}`} value={website.domain}>{`${website.domain}`}</option>)}
                    </CustomSelect>
                </div>
                <div style={{display: 'flex', gap: '2rem'}}>
                    {/* <div className={classes.customButton} onClick={toggleShowStack}><CustomButton color='blue' name={showStack ? names.hide_stack : names.show_stack}/></div> */}
                    <img src={exit} alt='X' onClick={props.toggle}/>
                </div>
            </div>
            {showCheckAnimation && <CheckAnimation />}
            {showFailAnimation && <FailAnimation />}
            {showDeleteConfirmation && <DeleteConfirmation onNo={() => setShowDeletConfirmation(false)} onYes={deleteHandler}/>}
            {showStack && <StackData names={names} data={stackData}/>}
            {!showStack &&
                <ResponsiveTable loadingResults={loadingResults}>
                <thead>
                    <tr>
                        <th><input type='checkbox' checked={selectAll} onChange={addRemoveAllTranscations}/></th>
                        <th>{names.domain}</th>
                        <th>{names.type}</th>
                        <th>{names.USD}</th>
                        <th>{names.NIS}</th>
                        <th>{names.nisRate}</th>
                        <th>{names.added}</th>
                        <th>{names.count}</th>
                        <th>{names.paid}</th>
                        <th>{names.manage}</th>
                    </tr>
                </thead>
                <tbody className={classes.table_data}>
                    {transferData.map((item,index) => <CustomerTranscationsTableItem
                                                        key={index} 
                                                        data={item}
                                                        names={names}
                                                        selectAll={selectAll}
                                                        stack={stackData}
                                                        addWebsiteToStackHandler={addWebsiteToStackHandler}
                                                        addToStackByDate={addToStackByDate}
                                                        setTransactionToDelete={setTransactionToDelete} 
                                                        setShowDeletConfirmation={setShowDeletConfirmation}/>)}
                </tbody>
            </ResponsiveTable>            
            }

            <div className={classes.buttons}>
                <div className={classes.reset} onClick={mark_as_paid}>{names.buttons.mark_as_paid}</div>
                <div className={classes.reset} onClick={mark_as_not_paid}>{names.buttons.mark_as_not_paid}</div>
                <div className={classes.cancel} onClick={props.toggle}>{names.buttons.cancel}</div>
                <div className={classes.customButton} onClick={submitHandler}><CustomButton reverse color='red' name={names.buttons.submit}/></div>
            </div>

            </div>
        </GrayWindow>
    )
}

export default ViewCustomerTranscations;