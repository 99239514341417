import classes from './CustomersTableItem.module.scss';

import logs_blue from '../../../assets/icons/logs_blue.svg'
import pencil from '../../../assets/icons/pencil_red.svg'
import pencil_blue from '../../../assets/icons/pencil.svg'
import summarize from '../../../assets/icons/summarize.svg'
import payments from '../../../assets/icons/payments_blue.svg'
import payments_red from '../../../assets/icons/payments_red.svg'
import dollar from '../../../assets/icons/dollar_green.svg'

import CustomToggle from '../../Customs/CustomToggle/CustomToggle';
import TransactionsHistory from './TranscationsHistory/TransactionsHistory';

import { fullYearParser } from '../../../utils/helper_functions';

import { useState } from 'react';
import { useSelector,useDispatch} from 'react-redux';
import { useNavigate } from 'react-router';
import { uiActions } from '../../../store/ui-slice';
const CustomersTableItem = (props) => {

    const [custmerStatus, setCustmerStatus] = useState(props.data.status === 'ACTIVE' ? true : false)
    const [showTranscationHistory, setShowTranscationHistory] = useState(false)
    const isAdmin = useSelector(state => state.user.isAdmin)

    const api_token = useSelector(state => state.user.token)

    const data = props.data
    const full_name = `${data.firstName} ${data.lastName}`
    const last_transaction = data.paymentHistory[0]?.paidDate ? fullYearParser(props.data.paymentHistory[0]?.paidDate*1000) : 'None'
    const added = fullYearParser(data.added*1000)
    const last_login = fullYearParser(data.lastLogin*1000)
    const websites_amount = data.websites.length
    const customer_is_active = data.status === 'ACTIVE'

    const API_URL = process.env.REACT_APP_API_URL
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const updateCustomerStatus = async () => {

        if(!isAdmin) {
            alert('You dont have premission to do this action')
            return;
        }

        let response = await fetch(`${API_URL}/api/v2/employees/customers`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                customerID: data.customerID,
                status: custmerStatus === true ? 'INACTIVE' : 'ACTIVE'
            })
        })

        let res_status = response.status
        response = await response.json()

        if(res_status === 200) setCustmerStatus(val => !val)
    }

    const toggleShowTransactionHistory = () => {
        setShowTranscationHistory(val => !val)
    }

    const impersonateHandler = async () => {

        if(!isAdmin) {
            alert('You dont have premission to do this action')
            return;
        }

        let response = await fetch(`${API_URL}/api/v1/login/impersonate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({customerId: data.customerID})
        })
        const res_status = response.status
        response = await response.json()

        if(res_status === 200) {
            localStorage.setItem('prev_token', localStorage.getItem('access_token'))
            localStorage.setItem('prev_role', localStorage.getItem('role'))
            localStorage.removeItem('access_token')
            localStorage.removeItem('role')
            localStorage.setItem('access_token', response.access_token)
            localStorage.setItem('role',response.role)
            dispatch(uiActions.setShowAdminInterface(false))
            dispatch(uiActions.setIsImpersonating(true))
            dispatch(uiActions.setIsLoggedIn(false))
            navigate('/', {replace: true})
        }


    }


    return (
        <>
        <tr className={classes.container} onMouseLeave={() => setShowTranscationHistory(false)}>
            {showTranscationHistory && <div className={classes.history}><TransactionsHistory data={data.paymentHistory}/></div>}
            <td className={classes.customer}>
                <div>{full_name}</div>
                <div className={classes.customer_icons}>
                    <img src={logs_blue} alt='impersonate' onClick={impersonateHandler}/>
                    <img src={pencil} alt='edit' onClick={props.onEditClick}/>
                </div>
            </td>
            <td className={classes.email}>{data.email}</td>
            <td>
                <div className={classes.website_container}>
                    <div className={classes.website}>
                        <span>{websites_amount}</span>
                    </div>
                    <div className={classes.website} onClick={props.onWebsiteClick} style={{cursor: 'pointer'}}>
                        <img src={pencil_blue} alt='edit websites'/>
                    </div>
                </div>
            </td>
            <td><CustomToggle name={'test'} onClick={updateCustomerStatus} checked={custmerStatus}/></td>
            <td>
                <div className={classes.money}>
                    <span>{`₪ ${data.totalBalance.NIS}`}</span>
                    <span>{`$ ${data.totalBalance.USD}`}</span>
                </div>
            </td>
            <td>
                <div className={classes.money}>
                    <span>{`₪ ${data.totalWithdrawn.NIS}`}</span>
                    <span>{`$ ${data.totalWithdrawn.USD}`}</span>
                </div>
            </td>
            <td className={classes.transcation} onClick={toggleShowTransactionHistory}>{last_transaction}</td>
            <td>
                <div className={classes.not_payed}>
                    <div className={classes.money}>
                        <span>{`₪ ${data.totalNotPaid.NIS}`}</span>
                        <span>{`$ ${data.totalNotPaid.USD}`}</span>
                    </div>
                    <div className={classes.actions}>
                        <img src={summarize} alt='list' onClick={props.onViewCustomerTranscations}/>
                        {((websites_amount > 0) && customer_is_active) && <img src={payments} alt='pay' onClick={props.onCreateTransfer}/>}
                        {((websites_amount <= 0) || !customer_is_active) && <img className={classes.forbid} src={payments_red} alt='pay'/>}
                    </div>
                </div>
            </td>
            <td>{added}</td>
            <td>{last_login}</td>
        </tr>
        </>
    )
}

export default CustomersTableItem;