import classes from './DeleteConfirmation.module.scss'

import CustomButton from '../../Customs/CustomButton';

const DeleteConfirmation = (props) =>{

    return (
        <div className={classes.container}>
            <div className={classes.title}>Are you sure you want to delete?</div>
            <div className={classes.buttons}>
                <CustomButton name='No' color='blue' reverse onClick={props.onNo}/>
                <CustomButton name='Yes' color='red' reverse onClick={props.onYes}/>
            </div>
        </div>
    )

}

export default DeleteConfirmation;