import classes from './CustomSelect.module.scss'

const CustomSelect = (props) => {

    if(props.topbar) {
        return (
            <div className={classes.container}>
                <select className={classes.select} onChange={props.onChange} disabled={props.disabled}>
                    {props.children}
                </select>
            </div>
        )
    }

    if(props.topbarmobile) {
        return (
            <div className={classes.container_mobile}>
                <select className={classes.select_mobile} onChange={props.onChange} disabled={props.disabled}>
                    {props.children}
                </select>
            </div>
        )
    }


    return (
        <div className={classes.container}>
            <select className={classes.select} onChange={(e) => props.onChange(e)} value={props.value} disabled={props.disabled}>
                {props.children}
            </select>
        </div>
    )
}

export default CustomSelect