
import approve from '../../../assets/icons/approved.svg'
import disapproved from '../../../assets/icons/disapproved.svg'

import { fullYearParser } from '../../../utils/helper_functions'

import { useState, useEffect } from 'react'

const FinalApprovmentTableItem = (props) => {

    const data = props.data
    const names = props.names
    let added = fullYearParser(data.created)

    const [selected, setSelected] = useState(props.selectAll)

    useEffect(() => {
        setSelected(props.selectAll)
    },[props.selectAll])

    const selectHandler = (e) => {
        const value = e.target.checked;

        if(value) {

            // if(props.stack.length > 0 && props.role === 'CFO') {
            //     alert('ניתן לבחור בקשה אחת בלבד')
            //     return;
            // }

            props.setStack(stack => [...stack,data.paymentRequestID])
            setSelected(e.target.checked)
        }
        else {
            const new_stack = props.stack.filter((item) => item !== data.paymentRequestID)
            props.setStack(new_stack)
            setSelected(e.target.checked)
        }
       
    }

    return (
        <tr>
            {!props.showSelectedRequests && <td><input type='checkbox' checked={selected} onChange={selectHandler}/></td>}
            <td>{`${data.customer.firstName} ${data.customer.lastName}`}</td>
            <td>{added}</td>
            <td>{`₪ ${data.amount.NIS}`}</td>
            <td style={{color: 'green', cursor: 'pointer', fontWeight: '500'}} onClick={() => props.onShowInvoice(data.receipt_img[0])}>{names.view}</td>
            <td>
                {(data.receipt_status === true || data.receipt_status === null) && <img src={approve} alt='approve'/>}
                {(data.receipt_status === false || data.receipt_status === null) && <img src={disapproved} alt='rejected'/>}
            </td>
            <td>
                {(data.receipt_tax_invoice === true || data.receipt_tax_invoice === null) && <img src={approve} alt='approve'/>}
                {(data.receipt_tax_invoice === false || data.receipt_tax_invoice === null) && <img src={disapproved} alt='rejected'/>}
            </td>
            <td>
                {(data.client_check === true || data.client_check === null) && <img src={approve} alt='approve'/>}
                {(data.client_check === false || data.client_check === null) && <img src={disapproved} alt='rejected'/>}
            </td>
            <td>
                {data.invoice_img[0] && <div style={{color: 'green', cursor: 'pointer', fontWeight: '500'}} onClick={() => props.onShowInvoice(data.invoice_img[0])}>{names.view}</div>}
                {!data.invoice_img[0] && <div style={{color: 'red', fontWeight: '500', cursor: 'not-allowed'}}>{names.not_available}</div>}
            </td>
            <td>{data.notes}</td>
        </tr>
    )
    
}

export default FinalApprovmentTableItem;