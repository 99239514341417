import classes from './OnAir.module.scss'

import notice from '../../assets/icons/notice.svg'
import down from '../../assets/icons/arrow_down_blue.svg'

import CustomButton from '../Customs/CustomButton'
import SearchBar from '../SearchBar/SearchBar'
import AmountSelect from '../AmountSelect/AmountSelect'
import Pagination from '../Pagination/Pagination'
import ResponsiveTable from '../Customs/ResponsiveTable/ResponsiveTable'
import DeleteConfirmation from '../Cards/DeleteConfirmation/DeleteConfirmation'

import HeadersOnAir from './HeadersOnAir'
import HeadersOnAirHistory from './HeadersOnAirHistory'
import OnAirTableItem from '../OnAirTableItem/OnAirTableItem'
import OnAirTableItemHistory from '../OnAirTableItemHistory/OnAirTableItemHistory'
import ConfirmationWindow from '../OnAirTableItem/ConfirmationWindow/ConfirmationWindow'

import AddNewCampaign from '../AddNewCampaign/AddNewCampaign'
import StatusDetails from '../OnAirTableItem/StatusDetails/StatusDetails'

import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'

import { urlCleaner } from '../../utils/helper_functions'


const OnAir = () => {

    const names = useSelector(state => state.ui.language.values)
    const title = names.websitesContent.onAir.general_information
    const api_token = useSelector(state => state.user.token)
    const isAdmin = useSelector(state => state.user.isAdmin)

    const [loadingResults, setLoadingResults] = useState(false)
    const [searchResults, setSearchResults] = useState('')
    const [pageNumber, setPageNumber] = useState(1)
    const [numberOfResults, setNumberOfResults] = useState(20)
    const [numberOfPages, setNumberOfPages] = useState(2)

    const [forceReload, setForceReload] = useState(false)

    const [showHistoryPage, setShowHistoryPage] = useState(false)
    const [showCreateNewCampaign, setShowCreateNewCampaign] = useState(false)
    const [showConfirmWindow, setShowConfirmWindow] = useState(false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [showStatusDetails, setShowStatusDetails] = useState(false)

    const [Data, setData] = useState([])
    const [DataRendered, setDataRendered] = useState([])

    const [confirmWindowData, setConfirmWidnowData] = useState({})
    const [deleteData, setDeleteData] = useState({})
    const [StatusDetailsData, setStatusDetailsData] = useState({})

    const API_URL = process.env.REACT_APP_API_URL

    useEffect( async() => {

        setLoadingResults(true)
        await importOnAirData()
        setLoadingResults(false)

    },[showHistoryPage,pageNumber,numberOfResults,forceReload])


    useEffect(() => {
        let temp_list;

        if(showHistoryPage) {
            temp_list = Data.filter((item) => {return item.domain.toLowerCase().includes(urlCleaner(searchResults))})   
        }
        if(!showHistoryPage) {
            temp_list = Data.filter((item) => {return item.customer.email.includes(searchResults)})
        }

        setDataRendered(temp_list)
    },[searchResults])


    const forceReloadHandler = () => {
        // value doesn't matter
        setForceReload(val => !val)
    }
    
    const importOnAirData = async () => {

        const response = await fetch(`${API_URL}/api/v2/campaigns`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                history: showHistoryPage,
                items: numberOfResults,
                page: pageNumber
            })
        })

        const data = await response.json()

        setData(data.campaigns)
        setDataRendered(data.campaigns)
        setNumberOfPages(Math.ceil(data.entries/numberOfResults))

    }

    const deleteHandler = async () => {

        let response = await fetch(`${API_URL}/api/v2/campaigns/action`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                campaignID: deleteData.id
            })
        })

        const res_status = response.status;
        response = await response.json()

        if(res_status === 200) {
            await importOnAirData()
            setShowDeleteConfirmation(false)
        }

    }


    const searchResultsHandler = (e) => {
        setSearchResults(e.target.value)
    }

    const toggleShowHistory = () => {
        setShowHistoryPage(val => !val)
        setSearchResults('')
        setPageNumber(1)
    }

    const toggleCreateNewCampaign = () => {
        setShowCreateNewCampaign(val => !val)
    }

    const toggleShowConfirmWindow = () => {
        setShowConfirmWindow(val => !val)
    }

    const toggleShowStatusDetails = () => {
        setShowStatusDetails(val => !val)
    }

    const confirmWindowHandler = (data) => {
        setConfirmWidnowData(data)
        setShowConfirmWindow(true)
    }

    return (
        <>
        {showCreateNewCampaign && <AddNewCampaign toggle={toggleCreateNewCampaign} setPageNumber={setPageNumber} forceReload={forceReloadHandler}/>}
        {showConfirmWindow && <ConfirmationWindow toggle={toggleShowConfirmWindow} data={confirmWindowData} setPageNumber={setPageNumber}/>}
        {showStatusDetails && <StatusDetails toggle={toggleShowStatusDetails} data={StatusDetailsData}/>}
        <div className={classes.container}>

            <div className={classes.general_info_container}>
                <div><img src={notice} alt='!'/></div>
                <span>{title}</span>
                <div className={classes.arrowdown}><img src={down} alt='v'/></div>
            </div>

            <div className={classes.main}>
                <div className={classes.main_top}>
                    <div className={classes.main_title}>{names.campaigns.title}</div>
                    <div className={classes.main_buttons}>
                        {!showHistoryPage && <CustomButton name={names.campaigns.history} color='red' onClick={toggleShowHistory}/>}
                        {(!showHistoryPage && isAdmin) && <CustomButton name={names.campaigns.create_new} color='red' reverse onClick={toggleCreateNewCampaign}/>}
                        {showHistoryPage && <div className={classes.onairbutton}><CustomButton name={names.campaigns.on_air} color='red' onClick={toggleShowHistory}/></div>}
                    </div>
                </div>

                <div className={classes.main_middle}>
                    <SearchBar placeholder={!showHistoryPage ? 'Search by email' : 'Search by domain'} value={searchResults} onChange={searchResultsHandler}/>
                    <AmountSelect pageNumber={pageNumber} numberOfResults={numberOfResults} numberOfPages={numberOfPages} onChange={(e) => setNumberOfResults(e.target.value)}/>
                </div>
                {showDeleteConfirmation && <DeleteConfirmation onNo={() => setShowDeleteConfirmation(false)} onYes={deleteHandler}/>}
                <ResponsiveTable loadingResults={loadingResults}>
                    <thead>
                        {!showHistoryPage && <HeadersOnAir names={names}/>}
                        {showHistoryPage && <HeadersOnAirHistory  names={names}/>}
                    </thead>
                    <tbody className={classes.table_data}>
                        {DataRendered.map((data,index) => !showHistoryPage ? <OnAirTableItem
                                                                            data={data}
                                                                            key={index}
                                                                            confirmWindowHandler={confirmWindowHandler}
                                                                            onDelete={() => {setDeleteData(data); setShowDeleteConfirmation(true)}}
                                                                            onStatusClick={() => {setStatusDetailsData(data); setShowStatusDetails(true)}}/>
                                                                            : <OnAirTableItemHistory data={data} key={index}/>)}
                    </tbody>
                </ResponsiveTable>

                <div className={classes.main_bottom}>
                    <AmountSelect pageNumber={pageNumber} numberOfResults={numberOfResults} numberOfPages={numberOfPages} onChange={(e) => setNumberOfResults(e.target.value)}/>
                    {numberOfPages > 1 && <Pagination pageNumber={pageNumber} setPageNumber={setPageNumber} numberOfPages={numberOfPages}/>}
                </div>
            </div>
        </div>
        </>
    )

}

export default OnAir