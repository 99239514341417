import classes from './CustomerWebsites.module.scss'

import CustomerWebsiteCard from '../Cards/CustomerWebsiteCard/CustomerWebsiteCard'

import { useSelector } from 'react-redux'

const CustomerWebsites = () => {

    let websites = useSelector(state => state.user.websites)

    return (
        <div className={classes.container}>
            <div className={classes.main}>
            {websites.map((website) => <CustomerWebsiteCard domain={website.domain} key={website.added} earned={website.earned} ownersince={website.added}/>)}
            </div>
        </div>
    )
}

export default CustomerWebsites