import classes from './ViewCustomerTranscations.module.scss'

import delete_icon from '../../assets/icons/delete_red.svg'
import add from '../../assets/icons/add.svg'

import { useState, useEffect } from 'react';

import { fullYearParser } from '../../utils/helper_functions';

const CustomerTranscationsTableItem = (props) => {

    const data = props.data
    let added = data.added ? fullYearParser(data.added*1000) : ''
    const inStack = props.stack.includes(data.transferID)

    const [checked, setChecked] = useState(props.selectAll || inStack)

    useEffect(() => {
        setChecked(props.selectAll)
    },[props.selectAll])

    useEffect(() => {
        const inStack = props.stack.find((item) => item.transferID === data.transferID)
        setChecked(inStack)
    },[props.stack])

    return (
        <tr >
            <td><input type='checkbox' checked={checked} onChange={(e) => {props.addWebsiteToStackHandler(data); setChecked(e.target.checked)} }/></td>
            <td style={{textTransform: 'none'}}>{`${data.domain}`}</td>
            <td>{`${data.type}`}</td>
            <td>{`${data.USD}`}</td>
            <td>{`${data.NIS}`}</td>
            <td>{`${data.nisRate}`}</td>
            <td ><div style={{cursor: 'pointer'}} onClick={() => props.addToStackByDate(data)}>{`${added}`}</div></td>
            <td>{`${data.count}`}</td>
            <td className={data.paid ? classes.paid : classes.notpaid}>{data.paid ? props.names.paid : props.names.not_paid}</td>
            <td>
                <div className={classes.options}>
                    {/* <img src={add} alt='add' onClick={() => addWebsiteToStackHandler(data)}/> */}
                    <img src={delete_icon} alt='delete' onClick={() => {props.setTransactionToDelete(data); props.setShowDeletConfirmation(true)}}/>
                </div>
            </td>
    </tr>
    )

}

export default CustomerTranscationsTableItem