
const CustomersHeaders = (props) => {

    const names = props.names

    return (
        <tr>
            <th style={{zIndex: 100}}>{names.customer}</th>
            <th style={{zIndex: 100}}>{names.email}</th>
            <th style={{zIndex: 100}}>{names.websites}</th>
            <th style={{zIndex: 100}}>{names.status}</th>
            <th style={{zIndex: 100}}>{names.revenue}</th>
            <th style={{zIndex: 100}}>{names.withdrawn}</th>
            <th style={{zIndex: 100}}>{names.last_transaction}</th>
            <th style={{zIndex: 100}}>{names.not_paid}</th>
            <th style={{zIndex: 100}}>{names.added}</th>
            <th style={{zIndex: 100}}>{names.last_login}</th>
        </tr>
    )
}

export default CustomersHeaders